@import"https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700;900&display=swap";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body {
    font-family: "Roboto", sans-serif;
    font-size: 16px;
    color: #333;
    line-height: 24px;
    scroll-behavior: smooth;
    font-weight: 400;
    background-color: #f0f0f0 !important;
    padding-right: 0px !important
}

html {
    background-color: #f0f0f0 !important
}

::-webkit-scrollbar {
    width: 6px
}

::-webkit-scrollbar:horizontal {
    height: 4px;
    background: rgba(199, 196, 196, .62)
}

::-webkit-scrollbar-thumb {
    -webkit-border-radius: 10px;
    border-radius: 10px;
    background: rgba(199, 196, 196, .62);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, .5)
}

::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(139, 138, 138, .771)
}

a {
    text-decoration: none !important
}

.w-img img {
    width: 100%
}

.m-img img {
    max-width: 100%
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none
}

a:focus,
a:hover {
    color: inherit;
    text-decoration: none
}

a,
button {
    color: inherit;
    outline: none;
    border: none;
    background: rgba(0, 0, 0, 0)
}

button:hover {
    cursor: pointer
}

button:focus {
    outline: 0;
    border: 0
}

.uppercase {
    text-transform: uppercase
}

.capitalize {
    text-transform: capitalize
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2c3941;
    line-height: 24px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

h1 {
    font-size: 40px
}

h2 {
    font-size: 36px
}

h3 {
    font-size: 24px
}

h4 {
    font-size: 20px
}

h5 {
    font-size: 16px
}

h6 {
    font-size: 14px
}

ul {
    margin: 0px;
    padding: 0px
}

li {
    list-style: none
}

p {
    font-size: 16px;
    line-height: 24px;
    text-align: justify
}

*::-moz-placeholder {
    color: #2c3941;
    font-size: 14px;
    opacity: 1
}

*::placeholder {
    color: #2c3941;
    font-size: 14px;
    opacity: 1
}

.fix {
    overflow: hidden
}

.clear {
    clear: both
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.z-index-1 {
    z-index: 1
}

.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible
}

.p-relative {
    position: relative
}

.react-read-more-read-less.react-read-more-read-less-less {
    color: #333 !important;
    font-weight: 500 !important
}

.react-read-more-read-less.react-read-more-read-less-more {
    color: #333 !important;
    font-weight: 500 !important
}

.content_body {
    padding-top: 75px;
    margin-bottom: 80px;
    position: relative
}

.media_content_body {
    margin-bottom: 50px;
    position: relative
}

.myItem {
    background-image: linear-gradient(to right, #7d6be0, #8367da, #8964d4, #8e60cd, #925dc7);
    height: 80px;
    width: 100%;
    border-radius: 10px;
    text-align: center;
    line-height: 80px;
    font-weight: 500;
    color: #fff;
    font-size: 22px;
    margin-bottom: 10px;
    cursor: pointer
}

.modal-title {
    font-size: 20px;
    color: #333
}

.MuiContainer-root {
    max-width: 1280px !important
}

.header {
    background-color: #7d6be0;
    z-index: 999;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%
}

.header .header_content {
    width: 100%
}

.header .header_content .header_item {
    display: flex
}

.header .header_content .header_item .logo {
    height: 50px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center
}

.header .header_content .header_item .logo img {
    max-height: 100%;
    max-width: 100%
}

.header .header_content .header_item .header_search {
    display: flex;
    align-items: center;
    margin-left: 20px
}

.header .header_content .header_item .header_search .search_box {
    max-width: 245px;
    width: 245px;
    position: relative;
    height: 45px
}

.header .header_content .header_item .header_search .search_box form .form_control {
    margin-bottom: 10px;
    width: 100%;
    height: 45px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 8px;
    border: 1px solid #dededf;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.header .header_content .header_item .header_search .search_box form .form_control:focus {
    outline: 0
}

.header .header_content .header_item .header_search .search_box form .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.header .header_content .header_item .header_search .search_box form .form_control:optional {
    color: #636262
}

.header .header_content .header_item .header_search .search_box form i {
    font-size: 20px;
    color: #7d6be0;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer
}

.header .header_content .header_item .header_search .notifi_list ul li {
    display: inline-block;
    position: relative;
    cursor: pointer;
    margin: 0 12px
}

.header .header_content .header_item .header_search .notifi_list ul li i {
    font-size: 28px;
    color: #fff
}

.header .header_content .header_item .header_search .notifi_list ul li .counting_overly {
    position: absolute;
    top: -10px;
    right: -8px;
    height: 20px;
    width: 20px;
    background-color: red;
    border-radius: 50%;
    text-align: center
}

.header .header_content .header_item .header_search .notifi_list ul li .counting_overly h5 {
    font-size: 11px;
    color: #fff;
    font-weight: 600;
    line-height: 21px
}

.header .header_content .header_item_wrapper {
    display: flex;
    height: 60px;
    align-items: center;
    width: 100%;
    justify-content: center
}

.header .header_content .header_item_wrapper .header_item_list {
    height: 43px;
    width: 43px;
    background-color: rgba(220, 215, 215, .41);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 10px
}

.header .header_content .header_item_wrapper .header_item_list .MuiSvgIcon-root {
    font-size: 26px;
    color: #fff
}

.header .header_content .header_item_wrapper2 {
    display: flex;
    height: 60px;
    align-items: center;
    width: 100%;
    justify-content: end
}

.header .header_content .header_item_wrapper2 .header_item_list {
    height: 43px;
    width: 43px;
    background-color: rgba(182, 181, 181, .6392156863);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center
}

.header .header_content .header_item_wrapper2 .header_item_list a .MuiSvgIcon-root {
    font-size: 28px;
    color: #fff
}

.header .header_content .header_item_wrapper2 .profile_dashed {
    margin-left: 10px
}

.header .header_content .header_item_wrapper2 .profile_dashed .MuiButtonBase-root {
    padding: 0px !important
}

.header .header_content .header_item_wrapper2 .profile_dashed .profile_img {
    height: 50px;
    width: 50px;
    border: 5px solid rgba(225, 223, 223, .8235294118);
    border-radius: 50%
}

.header .header_content .header_item_wrapper2 .profile_dashed .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper {
    width: 300px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    border-radius: 4px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_profile {
    display: flex;
    align-items: center;
    padding: 6px 10px;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    background-color: rgba(207, 214, 223, .5019607843);
    margin-bottom: 12px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_profile .userName {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    padding-left: 8px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list {
    padding: 6px 6px;
    display: flex;
    align-items: center;
    cursor: pointer
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list:hover {
    background-color: #f3f3f3;
    border-radius: 5px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list i {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    background-color: rgba(207, 214, 223, .5019607843);
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 7px
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list i .MuiSvgIcon-root {
    font-size: 22px;
    color: #333
}

.header .header_content .header_item_wrapper2 .profile_dashed .menu_wrapper .menu_wrapper_list span {
    font-size: 15px
}

.side-menu {
    position: fixed;
    margin-top: 90px;
    width: 270px
}

.side-menu .profile_main {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    transition: all linear .3s;
    border-radius: 13px;
    overflow: hidden;
    height: 300px;
    position: relative
}

.side-menu .profile_main .cover_photo {
    width: 100%;
    height: 130px;
    overflow: hidden
}

.side-menu .profile_main .cover_photo img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.side-menu .profile_main .profilr_debox {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 0 12px
}

.side-menu .profile_main .profilr_debox .image_container {
    margin-top: -68px
}

.side-menu .profile_main .profilr_debox .image_container .profile_img {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 2;
    border: 3px solid #fff;
    margin: 0 auto;
    position: relative;
    box-sizing: border-box
}

.side-menu .profile_main .profilr_debox .image_container .profile_img img {
    height: 95px;
    width: 95px;
    border-radius: 50%;
    object-fit: cover
}

.side-menu .profile_main .profilr_debox .followers_Box {
    text-align: center
}

.side-menu .profile_main .profilr_debox .followers_Box .followerIn_number {
    font-size: 18px;
    color: #333;
    font-weight: 600
}

.side-menu .profile_main .profilr_debox .followers_Box .f_Title {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    line-height: 16px
}

.side-menu .profile_main .user_control {
    padding: 5px 0
}

.side-menu .profile_main .user_control .user_name {
    text-align: center
}

.side-menu .profile_main .user_control .user_name a {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.side-menu .profile_main .user_control .user_name a:hover {
    border-bottom: 1px solid #333;
    transition: all linear .5s
}

.side-menu .profile_main .user_control .user_profile_name {
    font-size: 13px;
    color: rgba(99, 99, 99, .9333333333);
    font-weight: 400;
    text-align: center;
    line-height: 20px;
    font-family: "Open Sans", sans-serif
}

.side-menu .profile_main .user_control .user_shortDiscription {
    font-size: 14px;
    color: #888;
    text-align: center;
    line-height: 20px;
    font-weight: 400;
    padding-top: 15px
}

.side-menu .profile_main .user_control .profileBtn {
    text-align: center;
    padding: 8px;
    border-radius: 6px;
    background-color: #7d6be0;
    margin-top: 12px;
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 92%
}

.side-menu .profile_main .user_control .profileBtn a {
    font-size: 14px;
    font-weight: 400;
    color: #fff
}

.side-menu .sidebar_list {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    transition: all linear .3s;
    border-radius: 13px;
    overflow: hidden;
    padding: 10px;
    margin-top: 10px
}

.side-menu .sidebar_list ul {
    display: flex;
    flex-wrap: wrap;
    align-items: center
}

.side-menu .sidebar_list ul li {
    display: flex;
    align-items: center;
    margin: 4px;
    background-color: rgba(3, 40, 141, .0980392157);
    border-radius: 5px;
    height: 38px;
    padding: 0 10px
}

.side-menu .sidebar_list ul li span {
    padding-right: 5px;
    display: flex;
    align-items: center
}

.side-menu .sidebar_list ul li span img {
    width: 21px
}

.side-menu .sidebar_list ul li span {
    font-weight: 500;
    color: #888;
    font-size: 13px
}

.side-menu .Commuinity_Detail {
    margin-top: 10px
}

.side-menu .Commuinity_Detail ul {
    text-align: center
}

.side-menu .Commuinity_Detail ul li {
    display: inline-block;
    font-size: 14px;
    color: #888;
    font-weight: 400;
    padding: 0 14px;
    cursor: pointer
}

.side-menu .Commuinity_Detail ul li:hover {
    color: #7d6be0
}

.backArrow {
    height: 38px;
    width: 38px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    cursor: pointer;
    margin-bottom: 20px
}

.backArrow:hover {
    color: #333
}

.placeholder_text {
    text-align: center;
    color: rgba(0, 0, 0, .26);
    font-size: 20px;
    font-weight: 500;
    padding-top: 50px
}

.upload_loader {
    background-color: rgba(0, 0, 0, .6);
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1301;
}

.upload_loader .upload_loader_container h5 {
    font-size: 18px;
    color: #fff;
    font-weight: 400;
    padding-top: 15px
}

.loader-5 {
    margin: 0 auto;
    height: 60px;
    width: 60px;
    -webkit-animation: loader-5-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-1 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-1 {
    0% {
        -webkit-transform: rotate(0deg)
    }

    100% {
        -webkit-transform: rotate(360deg)
    }
}

@keyframes loader-5-1 {
    0% {
        transform: rotate(0deg)
    }

    100% {
        transform: rotate(360deg)
    }
}

.loader-5::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: auto;
    margin: auto;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: loader-5-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-2 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-2 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        -webkit-transform: translate3d(24px, 0, 0) scale(0.5)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }
}

@keyframes loader-5-2 {
    0% {
        transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        transform: translate3d(24px, 0, 0) scale(0.5)
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1)
    }
}

.loader-5::after {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: auto;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: loader-5-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-3 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-3 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        -webkit-transform: translate3d(-24px, 0, 0) scale(0.5)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }
}

@keyframes loader-5-3 {
    0% {
        transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        transform: translate3d(-24px, 0, 0) scale(0.5)
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1)
    }
}

.loader-5 span {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    height: 60px;
    width: 60px
}

.loader-5 span::before {
    content: "";
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: auto;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: loader-5-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-4 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-4 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        -webkit-transform: translate3d(0, 24px, 0) scale(0.5)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }
}

@keyframes loader-5-4 {
    0% {
        transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        transform: translate3d(0, 24px, 0) scale(0.5)
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1)
    }
}

.loader-5 span::after {
    content: "";
    display: block;
    position: absolute;
    top: auto;
    left: 0;
    bottom: 0;
    right: 0;
    margin: auto;
    width: 12px;
    height: 12px;
    background: #fff;
    border-radius: 50%;
    -webkit-animation: loader-5-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite;
    animation: loader-5-5 2s cubic-bezier(0.77, 0, 0.175, 1) infinite
}

@-webkit-keyframes loader-5-5 {
    0% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        -webkit-transform: translate3d(0, -24px, 0) scale(0.5)
    }

    100% {
        -webkit-transform: translate3d(0, 0, 0) scale(1)
    }
}

@keyframes loader-5-5 {
    0% {
        transform: translate3d(0, 0, 0) scale(1)
    }

    50% {
        transform: translate3d(0, -24px, 0) scale(0.5)
    }

    100% {
        transform: translate3d(0, 0, 0) scale(1)
    }
}

.noticeHome .notice_heading {
    background-color: #fff;
    padding: 10px 10px;
    border-radius: 8px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #7d6be0
}

.noticeHome .notice_heading .notice_header {
    font-size: 20px;
    font-weight: 500;
    color: #fff
}

.noticeHome .notice_heading .noticeAdder {
    height: 36px;
    width: 36px;
    background-color: #fff;
    border-radius: 8px;
    text-align: center
}

.noticeHome .notice_heading .noticeAdder i {
    line-height: 37px;
    color: #7d6be0;
    font-size: 20px
}

.notice_item {
    background-color: #fff;
    padding: 20px 20px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-top: 13px;
    border-radius: 13px
}

.notice_item .notice_Title {
    font-size: 22px;
    font-weight: 500;
    line-height: 28px;
    text-transform: uppercase;
    color: #333
}

.notice_item .profile_bio2 {
    display: flex;
    align-items: center;
    padding-top: 20px
}

.notice_item .profile_bio2 .profile_img {
    height: 45px;
    width: 45px;
    border-radius: 50%;
    overflow: hidden
}

.notice_item .profile_bio2 .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.notice_item .profile_bio2 .profile_info {
    padding-left: 6px;
    width: 100%
}

.notice_item .profile_bio2 .profile_info ul {
    display: flex;
    justify-content: space-between
}

.notice_item .profile_bio2 .profile_info ul li {
    font-size: 13px;
    color: #888;
    font-size: 400
}

.notice_item .profile_bio2 .profile_info ul li .profile_user_name {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    line-height: 15px
}

.notice_item .profile_bio2 .profile_info ul .profile_title {
    color: #7d6be0;
    display: inline;
    padding: 2px 15px;
    background-color: rgba(123, 106, 224, .7019607843);
    border-radius: 15px;
    color: #fff
}

.notice_item .profile_bio2 .profile_info ul .profile_title i {
    font-size: 13px;
    cursor: pointer
}

.notice_item .profile_bio2 .profile_info .post_time {
    font-size: 12px;
    line-height: 6px;
    color: #5c5c5c;
    font-weight: 400
}

.notice_item .noticeContent {
    padding-top: 12px;
    font-size: 14px;
    color: #5c5c5c;
    font-weight: 400;
    line-height: 22px
}

.notice_item .noticeItem_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px
}

.notice_item .noticeItem_footer .noticeItem_footer_left {
    display: flex;
    align-items: center
}

.notice_item .noticeItem_footer .noticeItem_footer_left .footerLeft_one {
    padding: 4px 12px;
    background-color: rgba(141, 123, 242, .2980392157);
    border-radius: 5px;
    margin-right: 12px;
    cursor: pointer
}

.notice_item .noticeItem_footer .noticeItem_footer_left .footerLeft_one i {
    color: #5c5c5c
}

.notice_item .noticeItem_footer .noticeItem_footer_left .footerLeft_one span {
    padding-left: 5px;
    font-size: 14px
}

.notice_item .noticeResponse {
    background-color: #ecebf6;
    padding: 8px;
    width: 100%;
    border-radius: 6px;
    margin-top: 12px;
    display: flex;
    align-items: center
}

.notice_item .noticeResponse .commnetorProfile {
    height: 38px;
    width: 38px;
    border-radius: 50%;
    overflow: hidden
}

.notice_item .noticeResponse .commnetorProfile img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.notice_item .noticeResponse .commentor_placehold {
    width: 100%;
    margin-left: 7px;
    height: 38px
}

.notice_item .noticeResponse .commentor_placehold .form_control {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    height: 38px;
    padding: 13px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    font-size: 14px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.notice_item .noticeResponse .commentor_placehold .form_control:focus {
    outline: 0
}

.notice_item .noticeResponse .commentor_placehold .form_control::placeholder {
    font-size: 13px;
    color: #aeacac;
    font-weight: 400
}

.notice_item .noticeResponse .commentor_placehold .form_control:optional {
    color: #636262
}

.notice_Image_board {
    height: 450px;
    width: 100%;
    border-radius: 13px;
    overflow: hidden;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.notice_Image_board .notice_Image_Top {
    height: 45%;
    padding: 10px
}

.notice_Image_board .notice_Image_Top .NoticeImg {
    height: 180px;
    width: auto;
    display: flex;
    align-items: center;
    justify-content: center
}

.notice_Image_board .notice_Image_Top .NoticeImg img {
    max-height: 100%;
    max-width: 100%
}

.notice_Image_board .notice_Image_Bottom {
    height: 55%;
    background-color: #7d6be0;
    padding: 14px;
    text-align: center
}

.notice_Image_board .notice_Image_Bottom .notice_announce {
    font-size: 32px;
    color: #fff;
    font-weight: 700;
    line-height: 42px;
    text-transform: uppercase;
    padding-top: 28px
}

.notice_Image_board .notice_Image_Bottom .notice_announce span {
    font-size: 24px;
    font-weight: 500
}

.notice_Image_board .notice_Image_Bottom p {
    font-size: 14px;
    color: #f3f3f3;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    padding-top: 10px
}

.mamber_hedingSIde {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px
}

.mamber_hedingSIde .headingSection {
    font-size: 20px;
    color: #333;
    font-weight: 500
}

.mamber_hedingSIde .mamber_hedingSide_right .addMamber_btn {
    padding: 12px 30px;
    background-color: #7d6be0;
    border-radius: 6px;
    color: #fff
}

.mamber .mamber_wrapper .memberItem {
    padding: 15px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    height: 270px;
    border-radius: 12px
}

.mamber .mamber_wrapper .memberItem .user_info .user_img {
    height: 82px;
    width: 82px;
    margin: 0 auto
}

.mamber .mamber_wrapper .memberItem .user_info .user_img img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 50%
}

.mamber .mamber_wrapper .memberItem .user_info .user_name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    text-align: center;
    padding: 10px 0 0 0
}

.mamber .mamber_wrapper .memberItem .user_info .join_date {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    text-align: center;
    padding-bottom: 10px
}

.mamber .mamber_wrapper .memberItem .icon_list {
    display: flex;
    justify-content: center
}

.mamber .mamber_wrapper .memberItem .icon_list .icon {
    height: 37px;
    width: 37px;
    border: 1px solid #d5d5d5;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0 5px;
    cursor: pointer
}

.mamber .mamber_wrapper .memberItem .icon_list .icon .MuiSvgIcon-root {
    font-size: 21px;
    color: #333
}

.mamber .mamber_wrapper .memberItem .owner_member {
    text-align: center;
    background-color: #7d6be0;
    color: #fff;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    height: 35px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase
}

.mamber .mamber_wrapper .memberItem .active_member {
    text-align: center;
    color: #888;
    font-weight: 400;
    font-size: 12px;
    width: 100%;
    height: 35px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase
}

.mamber .mamber_wrapper .memberItem .memberApproved_btn {
    text-align: center;
    cursor: pointer;
    display: inline-block;
    width: 100%;
    margin: 0 auto
}

.mamber .mamber_wrapper .memberItem .memberApproved_btn .MuiButtonBase-root {
    font-size: 12px
}

.mamber .mamber_wrapper .memberItem .memberCard_footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-top: 15px
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_left {
    width: 85%
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right {
    width: 15%;
    display: flex;
    justify-content: end;
    position: relative
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right:hover .member_dropdown_Plate {
    display: block
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .memeber_dropDown {
    height: 34px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    cursor: pointer
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .memeber_dropDown:hover {
    background-color: rgba(232, 230, 230, .6745098039)
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .memeber_dropDown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .member_dropdown_Plate {
    width: 180px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    right: -5px;
    bottom: 30px;
    display: none;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239);
    overflow: hidden
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .member_dropdown_Plate ul li {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer
}

.mamber .mamber_wrapper .memberItem .memberCard_footer .memberCard_footer_right .member_dropdown_Plate ul li:hover {
    background-color: #f3f3f3
}

.MemberSearch_body {
    background-color: #fff;
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px;
    width: 500px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.MemberSearch_body .memberSearch_wrapper {
    position: relative;
    width: 450px;
    margin-top: 20px
}

.MemberSearch_body .memberSearch_wrapper .form_contorl {
    height: 48px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 35px;
    border: 1px solid #d5d5d5;
    outline: 0;
    padding: 0 15px;
    font-size: 15px
}

.MemberSearch_body .memberSearch_wrapper .form_contorl::placeholder {
    color: #888
}

.MemberSearch_body .memberSearch_wrapper i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer
}

.MemberSearch_body .memberSearch_wrapper i .MuiSvgIcon-root {
    color: #888
}

.addMember_card {
    width: 540px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 20px;
    margin: 0 auto;
    border-radius: 10px
}

.dropdown_menuTab {
    width: 200px;
    padding: 0 6px
}

.dropdown_menuTab ul li {
    padding: 4px 5px;
    font-size: 15px;
    cursor: pointer
}

.dropdown_menuTab ul li .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 2px
}

.dropdown_menuTab ul li:hover {
    background-color: rgba(221, 217, 217, .6941176471)
}

.lounge {
    padding-bottom: 22px
}

.lounge .O_user_list {
    margin-bottom: 12px
}

.lounge .O_user_list .Other_User {
    height: 128px;
    width: 90px;
    padding: 10px 6px;
    border-radius: 8px;
    background-color: #fff;
    text-align: center;
    border: 1px solid #d5d5d5;
    cursor: pointer
}

.lounge .O_user_list .Other_User .userImg_content {
    height: 72px;
    width: 72px;
    margin: 0 auto;
    border-radius: 50%
}

.lounge .O_user_list .Other_User .userImg_content img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.lounge .O_user_list .Other_User .userName {
    font-size: 12px;
    color: #888;
    font-weight: 500;
    line-height: 16px;
    padding-top: 4px
}

.lounge .O_user_list .swiper .swiper-wrapper .swiper-slide {
    width: 96px !important
}

.lounge .O_user_list .swiper .swiper-button-next {
    right: 0;
    height: 35px;
    width: 35px;
    background-color: #fff;
    text-align: center;
    line-height: 35px;
    border-radius: 50%
}

.lounge .O_user_list .swiper .swiper-button-next::after {
    font-size: 18px;
    color: #333
}

.lounge .O_user_list .swiper .swiper-button-prev {
    left: 0;
    height: 35px;
    width: 35px;
    background-color: #fff;
    text-align: center;
    line-height: 35px;
    border-radius: 50%
}

.lounge .O_user_list .swiper .swiper-button-prev::after {
    font-size: 18px;
    color: #333
}

.lounge .lounge_right_fixed {
    position: relative;
    width: 100%
}

.lounge .lounge_right_fixed .lounge_right {
    width: 297px
}

.lounge .lounge_right_fixed .lounge_right_sticky {
    position: fixed;
    top: 75px
}

.lounge .upload_box_container {
    width: 100%;
    padding: 18px 18px 18px 18px;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-bottom: 20px;
    cursor: pointer
}

.lounge .upload_box_container .upload_box_top {
    display: flex
}

.lounge .upload_box_container .upload_box_top .uploader_profile {
    width: 45px;
    height: auto
}

.lounge .upload_box_container .upload_box_top .uploader_profile img {
    height: 44px;
    width: 44px;
    overflow: hidden;
    border-radius: 50%;
    object-fit: cover
}

.lounge .upload_box_container .upload_box_top .uploader_input {
    width: 100%;
    margin-left: 8px
}

.lounge .upload_box_container .upload_box_top .uploader_input .form_control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.lounge .upload_box_container .upload_box_top .uploader_input .form_control:focus {
    outline: 0
}

.lounge .upload_box_container .upload_box_top .uploader_input .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.lounge .upload_box_container .upload_box_top .uploader_input .form_control:optional {
    color: #636262
}

.lounge .upload_box_container .upload_box_bottom {
    padding-top: 5px;
    display: flex;
    justify-content: flex-end
}

.lounge .upload_box_container .upload_box_bottom .dec_item {
    margin-right: 5px;
    font-size: 14px;
    font-weight: 500;
    margin-left: 20px;
    display: flex;
    align-items: center
}

.lounge .upload_box_container .upload_box_bottom .dec_item .MuiSvgIcon-root {
    font-size: 22px
}

.lounge .upload_box_container .upload_box_bottom .photo_c .MuiSvgIcon-root {
    color: #378fe9
}

.lounge .upload_box_container .upload_box_bottom .tag_c .MuiSvgIcon-root {
    color: #c37d16
}

.lounge .upload_box_container .upload_box_bottom .location_c .MuiSvgIcon-root {
    color: #5f9b41
}

.longue_up {
    width: 100%;
    padding: 18px;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-bottom: 30px
}

.longue_up h4 {
    font-size: 20px;
    color: #7d6be0;
    font-weight: 600;
    text-transform: uppercase;
    padding-bottom: 8px;
    display: inline-block
}

.longue_up .profile_bio_of_upload {
    display: flex
}

.longue_up .profile_bio_of_upload .profile_img {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    overflow: hidden
}

.longue_up .profile_bio_of_upload .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.longue_up .profile_bio_of_upload .upload_item {
    padding-left: 6px;
    width: 100%
}

.longue_up .profile_bio_of_upload .upload_item .input_field .form_control {
    display: block;
    margin-bottom: 10px;
    width: 100%;
    height: 70px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.longue_up .profile_bio_of_upload .upload_item .input_field .form_control:focus {
    outline: 0
}

.longue_up .profile_bio_of_upload .upload_item .input_field .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.longue_up .profile_bio_of_upload .upload_item .input_field .form_control:optional {
    color: #636262
}

.UploadModal_Title {
    text-align: center
}

.MuiDialog-paper {
    background-color: none !important;
    overflow: visible
}

.dialog_header {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    text-align: center;
    padding: 15px 0;
    border-bottom: 1px solid rgba(202, 221, 235, .937);
    position: relative
}

.dialog_header .mediaBack_btn {
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    height: 35px;
    width: 35px;
    background-color: #e3e2ec;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.dialog_header .mediaBack_btn .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center
}

.upload_input_area {
    max-height: 360px;
    overflow-y: scroll
}

.upload_input_area .Upload_textarea {
    width: 440px;
    border: none !important
}

.upload_input_area .Upload_textarea::placeholder {
    font-size: 22px;
    color: #888;
    font-weight: 400
}

.upload_input_area .Upload_textarea:focus-visible {
    outline: 0 !important
}

.MuiDialog-paper {
    border-radius: 10px !important;
    overflow: visible !important
}

.dialogContent-1 {
    background-color: #fff;
    border-radius: 12px
}

.dialogContent-2 {
    background-color: #fff;
    animation: scale-display .3s;
    border-radius: 12px
}

.dialogContent-2 .mediaAdd_Body .mediaAdder {
    width: 500px;
    padding: 20px;
    max-height: 460px;
    overflow-y: scroll;
    min-height: 260px
}

.dialogContent-2 .TagBody {
    width: 440px;
    min-height: 280px;
    max-height: 300px;
    overflow-y: scroll
}

.dialogContent-2 .TagBody .css-l4u8b9-MuiInputBase-root-MuiInput-root:before {
    border-bottom: 1px solid rgba(0, 0, 0, 0)
}

.dialogContent-2 .TagBody .css-l4u8b9-MuiInputBase-root-MuiInput-root:after {
    border-bottom: 2px solid rgba(0, 0, 0, 0)
}

.dialogContent-2 .TagBody .css-1q60rmi-MuiAutocomplete-endAdornment {
    display: none
}

@keyframes scale-display {
    0% {
        opacity: 0;
        transform: scale(0);
        -webkit-transform: scale(0)
    }

    100% {
        opacity: 1;
        transform: scale(1);
        -webkit-transform: scale(1)
    }
}

.dialog_footer {
    width: 100%;
    padding: 0 13px 13px 13px;
    position: relative
}

.dialog_footer .emoji_picker {
    position: absolute;
    right: -200px;
    top: -249px;
    border-radius: 12px;
    height: 260px;
    overflow: hidden;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239)
}

.dialog_footer .upload_attached {
    padding-top: 13px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.dialog_footer .upload_attached ul {
    display: flex
}

.dialog_footer .upload_attached ul li {
    cursor: pointer;
    font-size: 16px;
    padding: 2px 15px;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 15px;
    margin-right: 12px;
    display: flex;
    align-items: center
}

.dialog_footer .upload_attached ul li:hover {
    background-color: #ebe9e9
}

.dialog_footer .upload_attached ul li .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 4px
}

.dialog_footer .upload_attached ul li .small-text {
    font-size: 12px
}

.dialog_footer .uploader_btn_area {
    margin-top: 20px
}

.dialog_footer .uploader_btn_area .btn {
    width: 100%;
    color: #fff;
    background-color: #378fe9
}

.longue_item {
    width: 100%;
    padding: 6px 0 4px 0;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-bottom: 12px
}

.longue_item .uploaded_img_container {
    position: relative;
    overflow: hidden
}

.longue_item .uploaded_img_container .single_img {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    overflow: hidden;
    padding: 0 0
}

.longue_item .uploaded_img_container .single_img img {
    max-height: 600px;
    max-width: 100%;
    z-index: 1;
    cursor: pointer
}

.longue_item .uploaded_img_container .image_overly {
    position: absolute;
    transform: scale(1.05);
    filter: blur(15px);
    -webkit-filter: blur(15px);
    height: 100%;
    width: 100%;
    object-fit: cover;
    opacity: .8;
    top: 0;
    left: 0
}

.longue_item .uploaded_img_container .image_overly img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.longue_item .uploadede_item {
    position: relative;
    overflow: hidden;
    margin-top: 2px;
    border-top: 1px solid #d5d5d5
}

.longue_item .uploadede_item .image_container {
    height: 220px;
    width: 100%;
    overflow: hidden;
    cursor: pointer
}

.longue_item .uploadede_item .image_container img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.longue_item .uploadede_item .image_container .extra_img_overly2 {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .5);
    z-index: 4;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.longue_item .uploadede_item .image_container .extra_img_overly2 span {
    color: #fff;
    font-weight: 400;
    font-size: 40px
}

.longue_item .extra_img_relative {
    position: relative
}

.longue_item .uploaded_Video_container {
    width: 100%;
    overflow: hidden
}

.video-react {
    height: 550px !important;
    padding-top: 0px !important
}

.video-react .video-react-video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    max-height: 600px !important
}

.profile_bio {
    display: flex;
    align-items: center;
    padding: 8px 15px
}

.profile_bio .profile_img {
    display: flex;
    width: 55px
}

.profile_bio .profile_img img {
    height: 40px !important;
    width: 40px !important;
    object-fit: cover;
    border-radius: 50%
}

.profile_bio .profile_info {
    padding-left: 2px;
    width: 100%
}

.profile_bio .profile_info .userName a {
    font-size: 16px;
    color: #333;
    font-weight: 500
}

.profile_bio .profile_info .userName span {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    padding-left: 10px
}

.profile_bio .profile_info .post_timing {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 16px
}

.profile_bio .profile_info .post_timing .MuiSvgIcon-root {
    font-size: 16px
}

.profile_bio .postInfo {
    display: flex;
    align-items: center
}

.profile_bio .postInfo .post_back_btn {
    height: 40px;
    width: 40px;
    background-color: rgba(231, 228, 228, .8039215686);
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 10px
}

.profile_bio .postInfo .post_back_btn .MuiSvgIcon-root {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888
}

.LoungeItemContent {
    padding-bottom: 3px
}

.LoungeItemContent .loungeContent {
    padding: 12px 15px 0 15px;
    font-size: 14px;
    color: #5c5c5c;
    font-weight: 400;
    line-height: 22px
}

.LoungeItemContent .loungeItem_footer {
    position: relative
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 10px 5px 10px
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update .status_update_left {
    display: flex
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update .status_update_left .statusUpdate_item {
    font-size: 13px;
    padding-right: 15px;
    display: flex;
    align-items: center;
    color: #888;
    cursor: pointer
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update .status_update_left .statusUpdate_item .MuiSvgIcon-root {
    font-size: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #42ba96
}

.LoungeItemContent .loungeItem_footer .loung_item_status_update .status_update_right .statusUpdate_item {
    font-size: 13px;
    cursor: pointer;
    color: #333
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status {
    margin-top: 2px;
    border-top: 1px solid #d5d5d5;
    padding: 5px 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one {
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 90%;
    cursor: pointer
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one .MuiSvgIcon-root {
    font-size: 19px
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one:hover {
    background-color: rgba(3, 40, 141, .062745098);
    transition: all linear .5s;
    border-radius: 4px
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one span {
    padding-left: 5px;
    font-size: 14px
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_status .footer_status_one .emoji_icon {
    padding-left: 0px;
    font-size: 24px
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_left {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 100%
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_left .footerLeft_one {
    padding: 4px 12px;
    margin-right: 12px;
    display: flex;
    align-items: center;
    cursor: pointer
}

.LoungeItemContent .loungeItem_footer .loungeItem_footer_left .footerLeft_one span {
    padding-left: 5px;
    font-size: 14px
}

.css-advny6-MuiPopper-root.MuiPopper-root {
    z-index: 2
}

.MuiPopper-root {
    z-index: 2 !important
}

.MuiPopper-root .dropdown_menu_item {
    font-size: 15px !important
}

.MuiPopper-root .dropdown_menu_item .MuiSvgIcon-root {
    font-size: 19px;
    margin-right: 2px
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
    min-height: auto;
    font-size: 13px
}

.css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px
}

.postView_title {
    text-align: center
}

.postView_container {
    height: auto;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    padding: 6px 0px 15px 0px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.postView_container .postView_top {
    padding-bottom: 3px
}

.postView_container .postView_container_Top .postViewModal_container_Image {
    height: 580px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #333
}

.postView_container .postView_container_Top .postViewModal_container_Image img {
    max-height: 100%;
    max-width: 100%
}

.postView_container .postView_container_Bottom {
    padding-top: 6px;
    position: relative
}

.postView_container .postView_container_Bottom .postViewModal_middle .modal_comment_area {
    padding-right: 10px
}

.comment_input_main {
    padding-left: 9px
}

.comment_input_main .comment_input_container {
    width: 100%;
    background-color: #fff;
    border: 1px solid #d5d5d5;
    border-radius: 10px
}

.comment_input_main .comment_input_container .comment_input_area {
    max-height: 220px;
    overflow-y: scroll
}

.comment_input_main .comment_input_container .comment_input_area .comment_textarea {
    padding: 10px 10px 5px 10px;
    color: #333;
    background-clip: padding-box;
    background-color: #fff;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border: none;
    width: 100%;
    font-size: 14px
}

.comment_input_main .comment_input_container .comment_input_area .comment_textarea::placeholder {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.comment_input_main .comment_input_container .comment_input_area .comment_textarea:focus-visible {
    outline: 0 !important
}

.comment_input_main .comment_input_container .comment_attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 2px 8px;
    background-color: #fff;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px
}

.comment_input_main .comment_input_container .comment_attachment ul {
    display: flex;
    align-items: center
}

.comment_input_main .comment_input_container .comment_attachment ul li {
    padding-right: 8px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer
}

.comment_input_main .comment_input_container .comment_attachment ul li .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px
}

.comment_input_main .comment_input_container .comment_attachment ul li .comment_emoji_picker {
    position: absolute;
    bottom: -260px;
    right: -290px;
    z-index: 999;
    border-radius: 12px;
    height: 260px;
    overflow: hidden;
    cursor: pointer;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239)
}

.comment_input_main .comment_input_container .comment_attachment .send_btn .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center
}

.comment_input_main .comment_input_container .comment_media_visiable {
    height: 60px;
    width: 90px;
    margin-left: 8px;
    margin-bottom: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

.comment_input_main .comment_input_container .comment_media_visiable img {
    max-width: 100%;
    max-height: 100%
}

.comment_input_main .comment_input_container .comment_media_visiable .comment_media_overly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3)
}

.comment_input_main .comment_input_container .comment_media_visiable .comment_media_overly .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer
}

.comment_container {
    padding-right: 18px
}

.comment_container ul {
    margin-left: 20px
}

.comment_container .comment_ul li {
    list-style-type: none;
    margin: 10px 0 10px 10px;
    position: relative
}

.comment_container .comment_ul li:before {
    content: "";
    position: absolute;
    top: -10px;
    left: -20px;
    border-left: 1px solid #d5d5d5;
    border-bottom: 1px solid #d5d5d5;
    width: 20px;
    height: 40px
}

.comment_container .comment_ul li:after {
    position: absolute;
    content: "";
    top: 29px;
    left: -20px;
    border-left: 1px solid #d5d5d5;
    border-top: 1px solid #d5d5d5;
    width: 20px;
    height: 100%
}

.comment_container .comment_ul li:last-child:after {
    display: none
}

.comment_container .comment_ul li .comment_wrapper {
    width: 100%;
    height: auto;
    background-color: #eee;
    border-radius: 8px;
    padding: 10px 10px 6px 10px
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top {
    display: flex;
    justify-content: space-between
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left {
    display: flex
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left .user_pic {
    width: 35px
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left .user_pic img {
    height: 32px;
    width: 32px;
    object-fit: cover;
    border-radius: 50%
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left .left_info .userName {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 18px
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_left .left_info .createDate {
    font-size: 11px;
    font-weight: 400;
    color: #888;
    line-height: 14px
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_right .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-size: 18px;
    cursor: pointer
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_right .paper_container .paper_list {
    padding: 3px 10px;
    font-size: 13px;
    display: flex;
    align-items: center;
    cursor: pointer
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_right .paper_container .paper_list:hover {
    background-color: #eee
}

.comment_container .comment_ul li .comment_wrapper .comment_wrap_top .comment_wrap_top_right .paper_container .paper_list .MuiSvgIcon-root {
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 3px
}

.comment_container .comment_ul li .comment_wrapper .comment_box {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    line-height: 18px;
    padding-top: 5px
}

.comment_container .comment_ul li .comment_wrapper .comment_media {
    height: 140px;
    width: auto;
    margin-top: 8px
}

.comment_container .comment_ul li .comment_wrapper .comment_media img {
    max-width: 100%;
    max-height: 100%
}

.comment_container .comment_ul li .comment_wrapper .comment_footer {
    padding-top: 6px
}

.comment_container .comment_ul li .comment_wrapper .comment_footer .comment_footer_left {
    display: flex
}

.comment_container .comment_ul li .comment_wrapper .comment_footer .comment_footer_left span {
    margin-right: 10px;
    cursor: pointer
}

.comment_container .comment_ul li .comment_wrapper .comment_footer .comment_footer_left .footer_react_item {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    margin-right: 15px;
    cursor: pointer
}

.comment_container .comment_ul li .comment_wrapper .comment_footer .comment_footer_left .footer_react_item:hover {
    color: #000
}

.css-1fu2e3p-MuiPaper-root-MuiDialog-paper {
    border-radius: 12px
}

.reply_input_plate {
    width: 100%;
    background-color: #eee;
    border: 1px solid #d5d5d5;
    border-radius: 10px;
    background-color: #fff
}

.reply_input_plate .reply_input_area {
    max-height: 220px;
    overflow-y: scroll
}

.reply_input_plate .reply_input_area .comment_textarea {
    padding: 10px 10px 5px 10px;
    color: #333;
    background-clip: padding-box;
    background-color: #fff;
    border-top-left-radius: 10px;
    border: none;
    width: 100%;
    font-size: 14px
}

.reply_input_plate .reply_input_area .comment_textarea::placeholder {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.reply_input_plate .reply_input_area .comment_textarea:focus-visible {
    outline: 0 !important
}

.reply_input_plate .reply_attachment {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 8px 1px 8px
}

.reply_input_plate .reply_attachment .reply_attachment_left {
    display: flex
}

.reply_input_plate .reply_attachment .reply_attachment_left .reply_attachment_item {
    padding-right: 8px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer
}

.reply_input_plate .reply_attachment .reply_attachment_left .reply_attachment_item .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px
}

.reply_input_plate .reply_attachment .reply_attachment_left .reply_attachment_item .reply_emoji_picker {
    position: absolute;
    bottom: -260px;
    right: -280px;
    z-index: 999;
    border-radius: 12px;
    height: 260px;
    overflow: hidden;
    cursor: pointer
}

.reply_input_plate .reply_attachment .send_btn .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center
}

.reply_input_plate .reply_media_visiable {
    height: 60px;
    width: 90px;
    margin-left: 8px;
    margin-bottom: 8px;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center
}

.reply_input_plate .reply_media_visiable img {
    max-width: 100%;
    max-height: 100%
}

.reply_input_plate .reply_media_visiable .reply_media_overly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3)
}

.reply_input_plate .reply_media_visiable .reply_media_overly .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 20px;
    cursor: pointer
}

.emojis_container {
    display: inline-block;
    line-height: .5;
    position: absolute;
    bottom: 30px;
    left: 10px
}

.emojis_container .emojiTab {
    padding: 15px 25px;
    background-color: #fff;
    border-radius: 35px;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239);
    display: flex;
    align-items: center;
    position: relative;
    top: 5px;
    left: 10px
}

.emojis_container .emojiTab .singleEmoji {
    font-size: 35px;
    padding: 0 4px;
    cursor: pointer
}

.comment_react_container {
    display: inline-block;
    position: absolute;
    bottom: 30px;
    left: 10px
}

.comment_react_container .comment_emoji_plate {
    padding: 4px 10px 2px 10px;
    background-color: #fff;
    border-radius: 35px;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239);
    display: flex;
    align-items: center
}

.comment_react_container .comment_emoji_plate .comment_emoji_icon {
    font-size: 19px;
    padding: 0 4px;
    cursor: pointer
}

.even_wrapper h4 {
    padding-bottom: 10px;
    font-size: 18px;
    color: #333;
    font-weight: 500
}

.event_item {
    margin-top: 5px;
    height: 150px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    position: relative
}

.event_item a {
    cursor: pointer
}

.event_item .event_title {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    line-height: 28px
}

.event_item .dropDown {
    height: 30px;
    width: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 15px;
    top: 15px;
    background-color: #f3f3f3;
    border-radius: 50%;
    cursor: pointer
}

.event_item .dropDown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.event_item .eventTime {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #333;
    font-weight: 400;
    padding-top: 3px
}

.event_item .eventTime .MuiSvgIcon-root {
    font-size: 20px;
    color: #333;
    margin-right: 3px
}

.event_item .eventTime span {
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px
}

.event_item .event_details {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    line-height: 20px;
    padding-top: 15px
}

.event_item_two {
    margin-top: 5px;
    height: 100px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 15px;
    position: relative
}

.event_item_two .event_title {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    line-height: 22px
}

.event_item_two .dropDown {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: 12px;
    top: 12px;
    border-radius: 50%;
    cursor: pointer
}

.event_item_two .dropDown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.event_item_two .eventTime {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #333;
    font-weight: 400;
    padding-top: 3px
}

.event_item_two .eventTime .MuiSvgIcon-root {
    font-size: 16px;
    color: #333;
    margin-right: 3px
}

.event_item_two .eventTime span {
    padding-left: 10px;
    font-weight: 500;
    font-size: 13px
}

.event_details_body {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 10px 10px rgba(1, 6, 20, .1);
    position: relative;
    overflow: hidden
}

.event_details_body .event_details_top {
    height: 220px;
    width: 100%;
    background-image: linear-gradient(to right top, #36a496, #2e8f87, #278783, #217f7f, #1c777a);
    padding: 20px;
    position: relative
}

.event_details_body .event_details_top .details_header {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.event_details_body .event_details_top .details_header h4 {
    font-size: 15px;
    color: #fff;
    font-weight: 300
}

.event_details_body .event_details_top .details_header .details_header_right {
    display: flex;
    align-items: center
}

.event_details_body .event_details_top .details_header .details_header_right .dropDown_icon {
    height: 32px;
    width: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(243, 243, 243, .231372549);
    border-radius: 50%;
    cursor: pointer;
    margin-left: 7px
}

.event_details_body .event_details_top .details_header .details_header_right .dropDown_icon .MuiSvgIcon-root {
    font-size: 22px;
    color: #fff
}

.event_details_body .event_details_top .event_info {
    border-top: 1px solid rgba(243, 243, 243, .2431372549);
    margin-top: 12px
}

.event_details_body .event_details_top .event_info .event_title {
    font-size: 26px;
    font-weight: 400;
    color: #fff;
    line-height: 34px;
    padding-top: 20px
}

.event_details_body .event_details_top .event_info .eventTime {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #fff;
    font-weight: 400;
    padding-top: 3px
}

.event_details_body .event_details_top .event_info .eventTime .MuiSvgIcon-root {
    font-size: 20px;
    color: #fff;
    margin-right: 3px
}

.event_details_body .event_details_top .event_info .eventTime span {
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px
}

.event_details_body .event_details_top .event_info .event_location {
    display: flex;
    align-items: center;
    font-size: 13px;
    color: #278783;
    font-weight: 400;
    padding-top: 3px;
    padding: 2px 10px;
    background-color: #fff;
    position: absolute;
    bottom: 12px;
    right: 20px;
    border-radius: 4px
}

.event_details_body .event_details_top .event_info .event_location .MuiSvgIcon-root {
    font-size: 18px;
    color: #278783;
    margin-right: 3px
}

.event_details_body .invite_section {
    padding: 20px;
    border-bottom: 1px solid #d5d5d5
}

.event_details_body .invite_section .invite_top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.event_details_body .invite_section .invite_top .invite_top_left {
    display: flex;
    align-items: center
}

.event_details_body .invite_section .invite_top .invite_top_left span {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    cursor: pointer
}

.event_details_body .invite_section .invite_top .invite_top_right .invite_btn {
    height: 35px;
    padding: 0 15px;
    border: 1px solid #1c777a;
    border-radius: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    color: #1c777a;
    cursor: pointer
}

.event_details_body .invite_section .invite_top .invite_top_right .invite_btn:hover {
    background-color: #1c777a;
    color: #fff
}

.event_details_body .invite_section .invite_top .invite_top_right .invite_btn:hover .MuiSvgIcon-root {
    color: #fff
}

.event_details_body .invite_section .invite_top .invite_top_right .invite_btn .MuiSvgIcon-root {
    font-size: 20px;
    color: #1c777a
}

.event_details_body .event_details {
    padding: 20px
}

.event_details_body .event_details p {
    font-size: 14px;
    line-height: 20px;
    color: #888;
    text-align: start
}

.event_details_body .details_file_container {
    padding: 0 20px 20px 20px;
    display: flex;
    flex-wrap: wrap
}

.event_details_body .details_file_container .file_tab {
    height: 180px;
    width: 180px;
    margin: 15px
}

.event_details_body .details_file_container .file_tab .ant-image {
    height: 200px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    margin-right: 10px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden
}

.event_details_body .details_file_container .file_tab .ant-image .ant-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.event_details_body .details_file_container .file_tab img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.event_create {
    padding: 25px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px
}

.event_create .event_form {
    padding-top: 20px
}

.event_create .event_form .input_field {
    width: 100%;
    position: relative
}

.event_create .event_form .input_field .form_cotrol {
    height: 50px;
    background-color: rgba(223, 222, 222, .2901960784);
    border: 1px solid #d5d5d5;
    width: 100%;
    border-radius: 6px;
    padding: 10px 12px
}

.event_create .event_form .input_field .form_cotrol:focus {
    outline: 0
}

.event_create .event_form .input_field .form_cotrol::placeholder {
    color: rgba(129, 128, 128, .8078431373)
}

.event_create .event_form .eventSubmit_btn {
    height: 40px;
    background-color: #1976d2;
    font-size: 15px;
    font-weight: 400;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    cursor: pointer
}

.event_create .event_form .field_heading {
    padding-bottom: 12px
}

.addGuest_wrapper {
    padding-top: 10px
}

.addGuest_wrapper h5 {
    font-size: 19px;
    font-weight: 500;
    color: #333
}

.addGuest_wrapper .invite_form {
    width: 100%;
    position: relative;
    margin-top: 10px
}

.addGuest_wrapper .invite_form .form_cotrol {
    height: 48px;
    background-color: rgba(233, 232, 232, .7490196078);
    width: 100%;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    padding: 10px 12px
}

.addGuest_wrapper .invite_form .form_cotrol:focus {
    outline: 0
}

.addGuest_wrapper .invite_form .form_cotrol::placeholder {
    color: rgba(129, 128, 128, .8078431373);
    font-size: 13px
}

.addGuest_wrapper .invite_form .addBtn {
    height: 33px;
    padding: 0 18px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    background-color: #1976d2;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.addGuest_wrapper .guestList_ShortView {
    padding-top: 10px;
    padding-left: 6px
}

.addGuest_wrapper .guestList_ShortView .MuiAvatarGroup-root {
    justify-content: start
}

.reviewsWrapper {
    padding: 20px;
    border-top: 1px solid #d5d5d5;
    margin-top: 20px
}

.reviewsWrapper .review_add_field {
    margin: 20px 0 10px 0
}

.reviewsWrapper .reviews_heading {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.reviewsWrapper .reviews_heading h4 {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.reviewsWrapper .submit_riview {
    display: flex;
    justify-content: end
}

.reviewsWrapper .submit_riview .review_submit_btn {
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    padding: 6px 15px;
    background-color: #2e8f87;
    border-radius: 4px;
    display: inline-block;
    cursor: pointer
}

.reviewsWrapper .review_item {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #d5d5d5;
    padding: 18px 0
}

.reviewsWrapper .review_item:last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0)
}

.reviewsWrapper .review_item .review_item_wrapper {
    display: flex
}

.reviewsWrapper .review_item .review_item_wrapper .reviewer_img img {
    height: 40px;
    width: 40px;
    object-fit: cover
}

.reviewsWrapper .review_item .review_item_wrapper .review_content {
    padding-left: 6px
}

.reviewsWrapper .review_item .review_item_wrapper .review_content .reviewr_info {
    font-size: 16px;
    font-weight: 500;
    color: #333
}

.reviewsWrapper .review_item .review_item_wrapper .review_content .reviewr_info span {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    padding-left: 6px
}

.reviewsWrapper .review_item .review_item_wrapper .review_content .review_massage {
    font-size: 14px;
    color: rgba(78, 77, 77, .8901960784);
    font-weight: 400;
    line-height: 21px
}

.reviewsWrapper .review_item .memberCard_footer_right {
    display: flex;
    justify-content: end;
    position: relative
}

.reviewsWrapper .review_item .memberCard_footer_right:hover .member_dropdown_Plate {
    display: block
}

.reviewsWrapper .review_item .memberCard_footer_right .memeber_dropDown {
    height: 34px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    border-radius: 4px;
    cursor: pointer
}

.reviewsWrapper .review_item .memberCard_footer_right .memeber_dropDown:hover {
    background-color: rgba(232, 230, 230, .6745098039)
}

.reviewsWrapper .review_item .memberCard_footer_right .memeber_dropDown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.reviewsWrapper .review_item .memberCard_footer_right .member_dropdown_Plate {
    width: 180px;
    height: auto;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    right: -5px;
    bottom: 30px;
    display: none;
    box-shadow: 2px 0px 15px rgba(1, 6, 20, .239);
    overflow: hidden
}

.reviewsWrapper .review_item .memberCard_footer_right .member_dropdown_Plate ul li {
    padding: 5px 10px;
    font-size: 14px;
    cursor: pointer
}

.reviewsWrapper .review_item .memberCard_footer_right .member_dropdown_Plate ul li:hover {
    background-color: #f3f3f3
}

.invite_dialouge_title {
    text-align: center
}

.invite_dialouge_body {
    min-height: 300px
}

.invite_dialouge_body .inviteSearch_wrapper {
    position: relative;
    width: 450px
}

.invite_dialouge_body .inviteSearch_wrapper .form_contorl {
    height: 48px;
    width: 100%;
    background-color: #f3f3f3;
    border-radius: 35px;
    border: 1px solid #d5d5d5;
    outline: 0;
    padding: 0 15px;
    font-size: 15px
}

.invite_dialouge_body .inviteSearch_wrapper .form_contorl::placeholder {
    color: #888
}

.invite_dialouge_body .inviteSearch_wrapper i {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer
}

.invite_dialouge_body .inviteSearch_wrapper i .MuiSvgIcon-root {
    color: #888
}

.invite_dialouge_body .invited_people_list {
    padding: 12px 0
}

.invite_dialouge_body .invited_people_list h5 {
    font-size: 18px;
    color: #888;
    font-weight: 400;
    padding-top: 5px
}

.invite_dialouge_body .invited_people_list ul {
    padding: 5px 10px 5px 15px
}

.invite_dialouge_body .invited_people_list ul li {
    list-style: decimal;
    margin-top: 10px
}

.invite_dialouge_body .invited_people_list ul li .invite_item_wrap {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.invite_dialouge_body .invited_people_list ul li .invite_item_wrap .people_name {
    font-size: 16px;
    font-weight: 500;
    color: #333
}

.invite_dialouge_body .invited_people_list ul li .invite_item_wrap .invite_status {
    font-size: 12px;
    font-weight: 400;
    color: #fff;
    background-color: #278783;
    border-radius: 35px;
    height: 28px;
    padding: 0 14px;
    display: flex;
    justify-content: center;
    align-items: center
}

.selector_body {
    width: 620px;
    min-height: 450px;
    padding: 20px
}

.classified_top_section .classified_top_img {
    height: 220px;
    width: 100%;
    position: relative;
    border-radius: 8px;
    overflow: hidden
}

.classified_top_section .classified_top_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.classified_top_section .classified_top_img .classified_top_imgOverly {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(0, 0, 0, .6);
    display: flex;
    justify-content: center;
    align-items: center
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt h4 {
    font-size: 22px;
    font-weight: 600;
    color: #fff;
    text-transform: uppercase;
    text-align: center
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search {
    width: 600px;
    margin: 0 auto
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form {
    margin-top: 20px
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar {
    position: relative;
    width: 100%
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control {
    display: block;
    width: 100%;
    height: 60px;
    padding: 0 25px;
    color: #333;
    border: 1px solid #d5d5d5;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control:focus {
    outline: 0
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control::placeholder {
    font-size: 16px;
    color: #aeacac;
    font-weight: 400
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar .form_control:optional {
    color: #636262
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search form .searchBar i {
    font-size: 20px;
    color: #333;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-left: 1px solid #b5b5b5;
    padding: 0 10px;
    cursor: pointer
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search .search_hint {
    font-size: 14px;
    color: #fff;
    font-weight: 600;
    padding-top: 12px;
    padding-left: 5px
}

.classified_top_section .classified_top_img .classified_top_imgOverly .overly_conetnt .classified_search .search_hint span {
    font-weight: 400;
    color: #f3f3f3;
    cursor: pointer;
    margin-right: 15px;
    border-bottom: 1px solid rgba(243, 243, 243, .431372549);
    padding-bottom: 1px
}

.classified_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px
}

.classified_heading .section_name {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.classified_cetegory_list {
    padding-top: 20px;
    padding-bottom: 25px
}

.classified_cetegory_list .list_category_item {
    height: 115px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.classified_cetegory_list .list_category_item .list_item_content {
    text-align: center
}

.classified_cetegory_list .list_category_item .list_item_content .category_img {
    height: 42px;
    width: 42px;
    margin: 0 auto
}

.classified_cetegory_list .list_category_item .list_item_content .category_img img {
    max-width: 100%;
    max-height: 100%
}

.classified_cetegory_list .list_category_item .list_item_content .categoryName {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    padding-top: 7px
}

.classified_cetegory_list .list_category_item .list_item_content .post_counting {
    font-size: 12px;
    color: #3d3d3d;
    font-weight: 500
}

.classiffied_add_btn {
    padding-bottom: 10px
}

.classified_create {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 22px 20px 25px 20px
}

.classified_create h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    line-height: 24px
}

.classified_create .classified_create_form {
    padding-top: 20px
}

.classified_create .classified_create_form .form_rowing {
    display: flex;
    align-items: center
}

.classified_create .classified_create_form .form_rowing .MuiFormLabel-root {
    font-weight: 600;
    margin-right: 20px
}

.classified_create .classified_create_form .field_heading {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding-bottom: 10px
}

.classified_create .classified_create_form .classified_images .classified_img_container {
    height: 100px;
    width: 100px
}

.classified_submit {
    margin-top: 15px
}

.classified_item {
    width: 100%;
    background-color: #fff;
    position: relative;
    height: 330px;
    box-shadow: 0px 1px 1px 1px rgba(128, 127, 127, .1294117647);
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer
}

.classified_item .classified_item_img {
    height: 230px;
    width: 100%
}

.classified_item .classified_item_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.classified_item .item_content {
    padding: 12px
}

.classified_item .item_content .post_title {
    font-size: 16px;
    font-weight: 500;
    color: #5c5c5c;
    line-height: 24px
}

.classified_item .post_bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    padding: 12px;
    position: absolute;
    bottom: 0;
    left: 0
}

.classified_item .post_bottom .post_amount {
    font-size: 18px;
    font-weight: 600;
    color: #333
}

.classified_item .post_bottom .cart_icon {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #ecebf6;
    display: flex;
    justify-content: center;
    align-items: center
}

.classified_item .post_bottom .cart_icon:hover {
    background-color: #7d6be0
}

.classified_item .post_bottom .cart_icon:hover .MuiSvgIcon-root {
    color: #fff
}

.classified_item .post_bottom .cart_icon .MuiSvgIcon-root {
    font-size: 20px
}

.classified_item .post_bottom .classified_option {
    height: 36px;
    width: 36px;
    border-radius: 50%;
    background-color: #ecebf6;
    display: flex;
    justify-content: center;
    align-items: center
}

.classified_item .post_bottom .classified_option:hover {
    background-color: #7d6be0
}

.classified_item .post_bottom .classified_option:hover .MuiSvgIcon-root {
    color: #fff
}

.classified_item .post_bottom .classified_option .MuiSvgIcon-root {
    font-size: 20px
}

.classified_itemLayout2 {
    width: 100%;
    background-color: #fff;
    border-radius: 8px;
    overflow: hidden;
    display: flex;
    border: 1px solid #d5d5d5;
    cursor: pointer
}

.classified_itemLayout2 .classified_item_img {
    height: 120px;
    width: 40%
}

.classified_itemLayout2 .classified_item_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.classified_itemLayout2 .item_content {
    padding: 12px;
    width: 60%;
    position: relative
}

.classified_itemLayout2 .item_content .post_title {
    font-size: 16px;
    font-weight: 500;
    color: #5c5c5c;
    line-height: 24px
}

.classified_itemLayout2 .item_content .post_bottom {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: end;
    padding: 12px;
    position: absolute;
    bottom: 0;
    left: 0
}

.classified_itemLayout2 .item_content .post_bottom .post_amount {
    font-size: 18px;
    font-weight: 600;
    color: #333
}

.classified_itemLayout2 .item_content .post_bottom .cart_icon {
    height: 34px;
    width: 34px;
    border-radius: 50%;
    background-color: #ecebf6;
    display: flex;
    justify-content: center;
    align-items: center
}

.classified_itemLayout2 .item_content .post_bottom .cart_icon:hover {
    background-color: #7d6be0
}

.classified_itemLayout2 .item_content .post_bottom .cart_icon:hover .MuiSvgIcon-root {
    color: #fff
}

.classified_itemLayout2 .item_content .post_bottom .cart_icon .MuiSvgIcon-root {
    font-size: 18px
}

.d_top {
    margin-top: 45px
}

.d_top h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    padding-bottom: 5px
}

.classified_item_detail {
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    width: 100%;
    border-radius: 12px;
    padding: 20px
}

.classified_item_detail .classified_item_Img_container {
    padding-top: 5px
}

.classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .swiper-slide {
    height: 400px;
    width: 100%;
    background-color: rgba(236, 235, 246, .662745098);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    border: 1px solid #d5d5d5
}

.classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .swiper-slide img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .navigate_img {
    height: 100px;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;
    height: 100px;
    width: 100%;
    object-fit: cover
}

.classified_item_detail .classified_item_Img_container .swiper .swiper-wrapper .swiper-slide-thumb-active {
    border: 1px solid #d5d5d5
}

.classified_item_detail .classified_item_Img_container .classified_img_navigate_content {
    padding-top: 15px
}

.classified_item_detail .swipper_item_content h4 {
    font-size: 21px;
    font-weight: 500;
    color: #61666f;
    line-height: 32px
}

.classified_item_detail .swipper_item_content .product_price {
    font-size: 30px;
    font-weight: 600;
    color: #333;
    padding-top: 4px
}

.classified_item_detail .swipper_item_content .product_price span {
    font-size: 18px
}

.classified_item_detail .swipper_item_content .seller_profile {
    display: flex;
    align-items: center;
    font-size: 14px;
    padding: 10px 0;
    color: #333;
    border-bottom: 1px solid #d5d5d5;
    cursor: pointer
}

.classified_item_detail .swipper_item_content .seller_profile:hover .MuiSvgIcon-root {
    color: #333
}

.classified_item_detail .swipper_item_content .seller_profile .MuiSvgIcon-root {
    color: #7d6be0;
    margin-right: 3px
}

.classified_item_detail .swipper_item_content .item_detail_list ul li {
    font-size: 14px;
    font-weight: 400;
    color: rgba(81, 81, 81, .7607843137);
    padding-top: 10px;
    display: flex;
    align-items: center
}

.classified_item_detail .swipper_item_content .item_detail_list ul li .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 5px
}

.classified_item_detail .swipper_item_content .item_detail_list ul li span {
    color: #333;
    font-weight: 500;
    padding-left: 5px;
    font-size: 15px
}

.classified_item_detail .swipper_item_content .item_detail_list .order_btn {
    height: 38px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-transform: uppercase;
    background-color: #2e7d32;
    border-radius: 35px;
    margin: 30px auto 0;
    cursor: pointer
}

.classified_item_detail .swipper_item_content .item_detail_list .order_btn:hover {
    background-color: #109817
}

.classified_item_detail .item_about {
    padding-top: 25px
}

.classified_item_detail .item_about h4 {
    font-size: 18px;
    color: #333;
    font-weight: 500
}

.classified_item_detail .item_about p {
    font-size: 15px;
    color: #888;
    line-height: 23px
}

.classified_item_detail .bidding_container .bidding_btn {
    height: 42px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    font-weight: 500;
    color: #fff;
    background-color: #2e7d32;
    border-radius: 35px;
    margin: 0 auto;
    cursor: pointer
}

.classified_item_detail .bidding_container .bidding_btn:hover {
    background-color: #109817
}

.top_classified_navigate {
    padding-bottom: 12px
}

.top_classified_navigate ul {
    display: flex;
    align-items: center
}

.top_classified_navigate ul li {
    display: flex;
    align-items: center;
    font-size: 12px;
    color: #888;
    font-weight: 400;
    padding-right: 15px;
    cursor: pointer
}

.top_classified_navigate ul .active {
    color: #333;
    font-size: 14px;
    font-weight: 500
}

.classified_order_form {
    width: 540px
}

.bid_form {
    width: 300px
}

.bidding_container .bidding_item {
    width: 700px;
    background-color: #ecebf6;
    border-radius: 10px;
    margin-top: 22px;
    padding: 12px;
    position: relative
}

.bidding_container .bidding_item .item_top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.bidding_container .bidding_item .item_top .top_left {
    display: flex
}

.bidding_container .bidding_item .item_top .top_left .user_img img {
    height: 45px;
    width: 45px;
    object-fit: cover;
    border-radius: 50%
}

.bidding_container .bidding_item .item_top .top_left .use_info {
    padding-left: 6px
}

.bidding_container .bidding_item .item_top .top_left .use_info .userName {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 22px
}

.bidding_container .bidding_item .item_top .top_left .use_info .time {
    font-size: 12px;
    font-weight: 400;
    color: #888;
    line-height: 16px
}

.bidding_container .bidding_item .item_top .bidamount {
    font-size: 20px;
    font-weight: 600;
    color: #333
}

.bidding_container .bidding_item .item_top .bidamount span {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    padding-right: 10px
}

.bidding_container .bidding_item .item_top .top_right .MuiSvgIcon-root {
    color: #888
}

.classified_orderList_container {
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    padding: 22px 20px 20px 20px
}

.classified_orderList_container h4 {
    font-size: 20px;
    font-weight: 500;
    color: #333
}

.order_list_wrapper {
    padding-top: 10px
}

.order_list_wrapper .order_list_wrap {
    border-bottom: 1px dashed #d5d5d5;
    padding: 20px 0
}

.order_list_wrapper .order_list_wrap:last-child {
    border-bottom: 1px dashed rgba(0, 0, 0, 0)
}

.order_list_wrapper .order_list_wrap .list_top {
    display: flex;
    justify-content: space-between
}

.order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id {
    font-size: 20px;
    color: #000;
    font-weight: 500;
    display: flex
}

.order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id .MuiSvgIcon-root {
    font-size: 22px;
    margin-right: 5px;
    color: #333
}

.order_list_wrapper .order_list_wrap .list_top .list_top_left .oredr_id span {
    padding: 1px 10px;
    background-color: #109817;
    color: #fff;
    border-radius: 35px;
    margin-left: 10px;
    font-size: 12px;
    font-weight: 400
}

.order_list_wrapper .order_list_wrap .list_top .list_top_right {
    font-size: 24px;
    font-weight: 700;
    color: #000
}

.order_list_wrapper .order_list_wrap .update_item_info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 8px;
    padding-left: 22px
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_left {
    font-size: 14px;
    font-weight: 400;
    color: #888;
    display: flex;
    align-items: center
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_left .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 2px;
    color: #888
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_right {
    display: flex
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_right .print_btn {
    font-size: 14px;
    color: #888;
    border: 1px solid #d5d5d5;
    padding: 1px 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    margin-left: 12px
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_right .dteilas_btn {
    font-size: 14px;
    color: #fff;
    background-color: #7d6be0;
    border: 1px solid #d5d5d5;
    padding: 1px 8px;
    display: flex;
    align-items: center;
    border-radius: 5px;
    cursor: pointer;
    margin-left: 12px
}

.order_list_wrapper .order_list_wrap .update_item_info .item_update_right .item_action_btn {
    font-size: 14px;
    color: #888;
    border: 1px solid #d5d5d5;
    height: 35px;
    width: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 12px
}

.order_list_wrapper .order_list_wrap .order_content {
    display: flex;
    align-items: center;
    padding-left: 22px;
    padding-top: 12px
}

.order_list_wrapper .order_list_wrap .order_content .item_img img {
    height: 55px;
    width: 55px;
    object-fit: cover;
    border-radius: 6px
}

.order_list_wrapper .order_list_wrap .order_content .item_info {
    padding-left: 5px
}

.order_list_wrapper .order_list_wrap .order_content .item_info .item_name {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    line-height: 20px
}

.order_list_wrapper .order_list_wrap .order_content .item_info .item_location {
    font-size: 13px;
    font-weight: 400;
    color: #888
}

.order_list_wrapper .order_list_wrap .order_content .item_info .item_location .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.order_info_wrapper {
    padding-top: 50px
}

.order_info_wrapper h5 {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    display: inline-block
}

.order_info_wrapper .order_info_wrap {
    padding-top: 12px
}

.order_info_wrapper .order_info_wrap .info_item {
    display: flex;
    align-items: center;
    border-bottom: 1px dashed #888;
    width: 100%;
    padding: 7px 0
}

.order_info_wrapper .order_info_wrap .info_item .info_left {
    font-size: 16px;
    font-weight: 400;
    color: #888;
    width: 20%
}

.order_info_wrapper .order_info_wrap .info_item .info_right {
    font-size: 18px;
    font-weight: 600;
    color: #333;
    width: 80%
}

.order_info_wrapper .order_info_wrap .info_item .info_right span {
    margin-right: 12px
}

.classifiedPost_dropDown {
    width: 200px;
    padding: 0 6px
}

.classifiedPost_dropDown ul li {
    padding: 4px 5px;
    font-size: 15px;
    cursor: pointer
}

.classifiedPost_dropDown ul li .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 2px
}

.classifiedPost_dropDown ul li:hover {
    background-color: rgba(221, 217, 217, .6941176471)
}

.job_filter {
    width: 100%;
    height: 600px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    position: fixed;
    width: 300px
}

.job_filter .job_filter_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #d5d5d5;
    padding: 10px 15px
}

.job_filter .job_filter_top .job_filter_top_left {
    font-size: 17px;
    color: #333;
    font-weight: 500
}

.job_filter .job_filter_top .job_filter_top_right {
    font-size: 14px;
    color: #ef1017;
    font-weight: 500
}

.job_filter .job_filter_Content {
    padding: 10px
}

.job_filter .job_filter_Content .job_filter_item {
    padding: 10px 0
}

.job_filter .job_filter_Content .job_filter_item h4 {
    font-size: 14px;
    color: #5c5c5c;
    font-weight: 400;
    line-height: 12px
}

.job_filter .job_filter_Content .job_filter_item .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
    padding: 10px 14px !important
}

.job_filter .job_filter_Content .job_filter_item .salaryRange_slider {
    padding-top: 30px
}

.job_filter .job_filter_Content .item_border {
    border-bottom: 1px solid #d5d5d5
}

.jobHome {
    background-color: #fff;
    padding: 20px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px
}

.jobHome_heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0 12px 0
}

.jobHome_heading .heading_left {
    font-size: 19px;
    color: #333;
    font-weight: 500
}

.resource {
    height: 300px;
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.section_headear {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px
}

.section_headear h4 {
    font-size: 24px;
    font-weight: 500;
    color: #333
}

.section_headear .btns_row {
    display: flex;
    align-items: center
}

.section_headear .btns_row .Btn_one {
    height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #7d6be0;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    margin-left: 15px;
    border: 1px solid #7d6be0;
    cursor: pointer
}

.section_headear .btns_row .Btn_one:hover {
    background-color: #fff;
    color: #7d6be0
}

.section_headear .btns_row .Btn_two {
    height: 40px;
    padding: 0 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    color: #7d6be0;
    border: 1px solid #7d6be0;
    font-size: 15px;
    margin-left: 15px;
    cursor: pointer
}

.section_headear .btns_row .Btn_two:hover {
    background-color: #7d6be0;
    color: #fff
}

.resource_wrapper {
    padding-top: 25px
}

.resource_item {
    height: 180px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 10px 10px rgba(1, 6, 20, .1);
    padding: 12px;
    position: relative
}

.resource_item:hover {
    box-shadow: 0px 20px 20px rgba(1, 6, 20, .1)
}

.resource_item .resource_title {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    line-height: 26px;
    cursor: pointer
}

.resource_item .resource_item_details {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 20px;
    padding-top: 8px;
    text-align: start;
    cursor: pointer
}

.resource_item .resource_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 12px 10px 12px
}

.resource_item .resource_footer .res_footer_left {
    display: flex;
    align-items: center
}

.resource_item .resource_footer .res_footer_left .admin_profile img {
    height: 25px;
    width: 25px;
    object-fit: cover;
    border-radius: 50%
}

.resource_item .resource_footer .res_footer_left .admin_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    padding-left: 3px
}

.resource_item .resource_footer .res_footer_right {
    display: flex;
    align-items: center
}

.resource_item .resource_footer .res_footer_right .resource_date {
    font-size: 13px;
    color: #888;
    font-weight: 400
}

.resource_item .resource_footer .res_footer_right .resource_date .MuiSvgIcon-root {
    font-size: 16px;
    margin-right: 1px
}

.resource_item .resource_footer .res_footer_right .admin_dropdown {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #f3f3f3;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
    cursor: pointer
}

.resource_item .resource_footer .res_footer_right .admin_dropdown .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 1px;
    color: #888
}

.resource_create {
    padding: 20px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px
}

.resource_create .resource_form {
    padding-top: 20px
}

.resource_create .resource_form .field_heading {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 500;
    color: #333
}

.resource_create .resource_form .resource_files_container {
    padding-top: 10px
}

.resource_create .resource_form h4 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    padding: 10px 0 0 0
}

.resource_details {
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px;
    width: 100%;
    padding: 25px;
    position: relative
}

.resource_details .admin_dropdown {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 38px;
    width: 38px;
    background-color: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.resource_details .admin_dropdown .MuiSvgIcon-root {
    color: #333;
    font-size: 20px
}

.resource_details .resource_admin_info {
    display: flex;
    align-items: center
}

.resource_details .resource_admin_info .admin_profile img {
    height: 38px;
    width: 38px;
    object-fit: cover;
    border-radius: 50%
}

.resource_details .resource_admin_info .admin_post_info {
    padding-left: 3px
}

.resource_details .resource_admin_info .admin_post_info .adminName {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    line-height: 20px
}

.resource_details .resource_admin_info .admin_post_info .postDate {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 14px
}

.resource_details h4 {
    font-size: 26px;
    font-weight: 500;
    color: #333;
    line-height: 35px;
    padding-bottom: 10px
}

.resource_details p {
    font-size: 15px;
    color: #888;
    line-height: 22px;
    font-weight: 400;
    text-align: start
}

.resource_details h5 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    padding-top: 40px
}

.resource_details .details_file_container {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap
}

.resource_details .details_file_container .file_tab .ant-image {
    height: 200px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    margin-right: 10px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden
}

.resource_details .details_file_container .file_tab .ant-image .ant-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.compnent_heading {
    font-size: 24px;
    font-weight: 500;
    color: #333;
    padding-bottom: 20px
}

.image_list_container {
    display: flex
}

.image_list_container .image_place {
    height: 120px;
    width: 120px;
    border-radius: 7px;
    background-color: #f3f3f3;
    position: relative;
    margin-right: 15px
}

.image_list_container .image_place img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px
}

.image_list_container .image_place .img_place_overly {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px
}

.image_list_container .image_place .img_place_overly .img_cross_btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #fff;
    color: #888;
    position: absolute;
    top: 8px;
    right: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.image_list_container .image_place .img_place_overly .img_cross_btn .MuiSvgIcon-root {
    font-size: 16px
}

.image_list_container .image_place_addMore {
    height: 120px;
    width: 120px;
    border-radius: 7px;
    background-color: #f3f3f3;
    position: relative;
    margin-right: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 15px;
    cursor: pointer
}

.Resource_uploadBox {
    width: 120px;
    height: 120px;
    background: rgba(0, 0, 0, .02);
    margin: .5em 0;
    position: relative;
    border-radius: 10px;
    border: 1px dashed #d9d9d9
}

.Resource_uploadBox:hover {
    background: #ecebf6
}

.Resource_uploadBox input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.Resource_uploadBox h1 {
    text-align: center;
    color: #333;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-weight: 400
}

.create_resource {
    height: 50px;
    width: 100%;
    padding: 0 20px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #7d6be0;
    color: #7d6be0;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center
}

.business_wrapper {
    padding-top: 30px
}

.business_item {
    height: 150px;
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    box-shadow: 0px 10px 10px rgba(1, 6, 20, .1);
    padding: 15px;
    position: relative
}

.business_item:hover {
    box-shadow: 0px 20px 20px rgba(1, 6, 20, .1)
}

.business_item .admin_dropdown {
    position: absolute;
    right: 15px;
    top: 15px;
    cursor: pointer;
    height: 30px;
    width: 30px;
    background-color: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center
}

.business_item .admin_dropdown .MuiSvgIcon-root {
    font-size: 20px;
    color: #888
}

.business_item .resource_title {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    line-height: 28px;
    padding-top: 4px;
    cursor: pointer
}

.business_item .business_item_details {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 20px;
    padding-top: 8px;
    text-align: start;
    cursor: pointer
}

.business_item .business_item_footer {
    position: absolute;
    bottom: 0;
    width: 100%;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 15px 15px 15px
}

.business_item .business_item_footer .business_item_left {
    display: flex;
    align-items: center
}

.business_item .business_item_footer .business_item_left .admin_profile img {
    height: 25px;
    width: 25px;
    object-fit: cover;
    border-radius: 50%
}

.business_item .business_item_footer .business_item_left .admin_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    padding-left: 3px
}

.business_item .business_item_footer .business_item_right {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    display: flex;
    align-items: center
}

.business_item .business_item_footer .business_item_right .MuiSvgIcon-root {
    font-size: 16px;
    margin-right: 2px
}

.business_item .business_type {
    padding: 1px 25px;
    border-radius: 35px;
    background-color: #ecebf6;
    font-size: 13px;
    color: #7d6be0;
    display: inline-block
}

.business_details {
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px;
    width: 100%;
    padding: 25px;
    position: relative
}

.business_details .admin_dropdown {
    position: absolute;
    right: 20px;
    top: 20px;
    height: 38px;
    width: 38px;
    background-color: #f3f3f3;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.business_details .admin_dropdown .MuiSvgIcon-root {
    color: #333;
    font-size: 20px
}

.business_details .resource_admin_info {
    display: flex;
    align-items: center
}

.business_details .resource_admin_info .admin_profile img {
    height: 38px;
    width: 38px;
    object-fit: cover;
    border-radius: 50%
}

.business_details .resource_admin_info .admin_post_info {
    padding-left: 3px
}

.business_details .resource_admin_info .admin_post_info .adminName {
    font-size: 15px;
    font-weight: 500;
    color: #333;
    line-height: 20px
}

.business_details .resource_admin_info .admin_post_info .postDate {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 14px
}

.business_details h4 {
    font-size: 26px;
    font-weight: 500;
    color: #333;
    line-height: 35px;
    padding-bottom: 10px
}

.business_details p {
    font-size: 14px;
    color: #888;
    line-height: 22px;
    font-weight: 400;
    text-align: start
}

.business_details h6 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    padding-top: 40px
}

.business_details .details_file_container {
    padding-top: 10px;
    display: flex;
    flex-wrap: wrap
}

.business_details .details_file_container .file_tab .ant-image {
    height: 200px;
    width: 180px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d5d5d5;
    margin-right: 10px;
    border-radius: 8px;
    cursor: pointer;
    overflow: hidden
}

.business_details .details_file_container .file_tab .ant-image .ant-image-img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 8px
}

.representative {
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 15px 15px
}

.representative .r_mamber {
    height: 250px;
    background-color: #ecebf6;
    border-radius: 6px;
    border: 1px solid #d5d5d5;
    padding: 25px 12px;
    margin-top: 15px;
    cursor: pointer;
    position: relative
}

.representative .r_mamber .man_category {
    display: inline-block;
    position: absolute;
    top: 10px;
    right: 10px
}

.representative .r_mamber .man_category img {
    height: 30px;
    width: 30px
}

.representative .r_mamber .man_profile {
    height: 100px;
    width: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto
}

.representative .r_mamber .man_profile img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.representative .r_mamber .m_name {
    font-size: 18px;
    font-weight: 500;
    color: #333;
    text-align: center;
    padding-top: 20px
}

.representative .r_mamber .man_title {
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #333;
    text-align: center;
    padding-top: 7px
}

.chat .chat_left {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    height: 85vh;
    border-radius: 13px;
    padding: 15px;
    overflow: hidden
}

.chat .chat_left .chat_left_top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.chat .chat_left .chat_left_top .chat_h {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    display: inline-block;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%)
}

.chat .chat_left .chat_search .search_box {
    width: 100%;
    position: relative;
    height: 45px;
    margin-top: 15px
}

.chat .chat_left .chat_search .search_box form .form_control {
    margin-bottom: 10px;
    width: 100%;
    height: 45px;
    padding: 10px;
    color: #333;
    background-color: rgba(229, 233, 237, .7215686275);
    background-clip: padding-box;
    border-radius: 7px;
    border: 1px solid #e1e1e1;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.chat .chat_left .chat_search .search_box form .form_control:focus {
    outline: 0
}

.chat .chat_left .chat_search .search_box form .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.chat .chat_left .chat_search .search_box form i {
    font-size: 20px;
    color: #7d6be0;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer;
    display: inline-block;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%)
}

.chat .chat_left .active_list {
    margin-top: 15px
}

.chat .chat_left .active_list .active_title {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    margin-bottom: 8px
}

.chat .chat_left .active_list .activeMamber {
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: inline-block;
    cursor: pointer;
    position: relative
}

.chat .chat_left .active_list .activeMamber img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.chat .chat_left .active_list .activeMamber .active_btn {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background-color: #3ccf4e;
    position: absolute;
    bottom: 2px;
    right: 2px
}

.chat .chat_left .chat_list {
    margin-top: 20px
}

.chat .chat_left .chat_list .chat_list_item {
    height: 70px;
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 10px 12px 10px;
    position: relative;
    border-radius: 8px;
    margin-bottom: 5px;
    background-color: rgba(3, 40, 141, .062745098);
    cursor: pointer
}

.chat .chat_left .chat_list .chat_list_item .profile_active {
    width: 20%
}

.chat .chat_left .chat_list .chat_list_item .profile_active .profile {
    height: 55px;
    width: 55px;
    border-radius: 50%;
    border: 4px solid rgba(231, 231, 231, .879);
    position: relative
}

.chat .chat_left .chat_list .chat_list_item .profile_active .profile img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.chat .chat_left .chat_list .chat_list_item .profile_active .profile .massage_count {
    height: 23px;
    width: 23px;
    background-color: #e94560;
    border-radius: 50%;
    text-align: center;
    font-size: 10px;
    color: #fff;
    position: absolute;
    top: -5px;
    right: -5px
}

.chat .chat_left .chat_list .chat_list_item .profile_active .active {
    border: 4px solid #3ccf4e
}

.chat .chat_left .chat_list .chat_list_item .chat_overview {
    width: 75%;
    padding-left: 5px
}

.chat .chat_left .chat_list .chat_list_item .chat_overview .profile-name {
    font-size: 15px;
    color: #333;
    font-weight: 500
}

.chat .chat_left .chat_list .chat_list_item .chat_overview .overview_massage {
    font-size: 13px;
    color: #7c7c7c;
    font-weight: 400;
    line-height: 16px
}

.chat .chat_left .chat_list .chat_list_item .lastMassage-time {
    font-size: 13px;
    font-weight: 500;
    display: inline-block;
    color: #5f5f5f;
    position: absolute;
    bottom: 0;
    right: 10px
}

.chat .chat_left .chat_list .chat_list_item .senting {
    position: absolute;
    top: 5px;
    right: 10px
}

.chat .chat_left .chat_list .chat_list_item .senting i {
    font-size: 14px;
    color: #888
}

.chat .chat_left .chat_list .active {
    background-color: rgba(124, 106, 224, .2745098039)
}

.chat .chatter_box {
    display: flex;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 13px;
    overflow: hidden
}

.chat .chatter_box .chat_right {
    height: 85vh;
    width: 100%;
    background-color: rgba(207, 176, 225, .4);
    padding: 13px;
    position: relative;
    z-index: 1
}

.chat .chatter_box .chat_right::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: -1;
    opacity: .7
}

.chat .chatter_box .chat_right .fixed_profilr_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    width: 100%;
    border-bottom: 1px solid #d5d5d5;
    position: absolute;
    top: 0;
    left: 0;
    padding: 12px 15px 10px 15px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_left {
    display: flex;
    align-items: center
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_left .profile {
    height: 45px;
    width: 45px;
    border-radius: 50%
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_left .profile img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_left .name_T {
    padding-left: 8px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_left .name_T .name {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    line-height: 16px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_left .name_T .active_status {
    font-size: 13px;
    color: #888;
    font-weight: 400
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul li {
    display: inline-block;
    cursor: pointer;
    height: 40px;
    width: 40px;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 50%;
    text-align: center;
    margin-left: 8px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul li i {
    color: #333;
    line-height: 40px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .call_drowpper {
    position: relative
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .call_drowpper .profileDahsed .subdropper4-extra {
    display: none;
    background-color: #fff;
    padding: 10px 10px 10px 10px;
    box-shadow: 1px .532px 1px 1px rgba(0, 0, 0, .2);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    overflow: hidden;
    width: 185px;
    border: none !important;
    padding: 0;
    z-index: 9;
    position: absolute;
    right: 0;
    top: 45px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .call_drowpper .profileDahsed .subdropper4-extra li {
    padding: 8px 12px;
    width: 100%;
    margin-left: 0px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .call_drowpper .profileDahsed .subdropper4-extra li:hover {
    background-color: rgba(125, 107, 224, .3294117647);
    width: 100%
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .call_drowpper .profileDahsed .subdropper4-extra li a {
    font-size: 14px;
    font-weight: 400;
    color: #000
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .call_drowpper .profileDahsed .subdropper4-extra li i {
    font-size: 15px;
    display: inline-block;
    width: 22px;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%)
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .chat-dropper-2 {
    position: relative
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .chat-dropper-2 .profileDahsed .sub_down4 {
    display: none;
    background-color: #fff;
    padding: 10px 10px 10px 10px;
    box-shadow: 1px .532px 1px 1px rgba(0, 0, 0, .2);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    overflow: hidden;
    width: 185px;
    border: none !important;
    padding: 0;
    z-index: 9;
    position: absolute;
    right: 0;
    top: 45px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .chat-dropper-2 .profileDahsed .sub_down4 li {
    padding: 8px 12px;
    width: 100%;
    margin-left: 0px
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .chat-dropper-2 .profileDahsed .sub_down4 li:hover {
    background-color: rgba(125, 107, 224, .3294117647);
    width: 100%
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .chat-dropper-2 .profileDahsed .sub_down4 li a {
    font-size: 14px;
    font-weight: 400;
    color: #000
}

.chat .chatter_box .chat_right .fixed_profilr_header .ct_right .chat_title_dashed ul .chat-dropper-2 .profileDahsed .sub_down4 li i {
    font-size: 15px;
    display: inline-block;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    width: 22px
}

.chat .chatter_box .chat_right .chat_body {
    margin-top: 55px
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item {
    width: 100%;
    display: flex;
    margin: 10px 0
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .profile_part {
    width: 45px
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .profile_part .chatter_profile {
    height: 45px;
    width: 45px;
    border-radius: 50%
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .profile_part .chatter_profile img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .chatter_chat {
    padding-left: 7px;
    min-width: 120px;
    max-width: 70%;
    position: relative
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .chatter_chat:hover .massage_D {
    opacity: 1
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .chatter_chat .senting_massage {
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6px 7px 24px 8px;
    background-color: #fff;
    font-size: 14px;
    line-height: 20px;
    font-weight: 400;
    color: #888;
    position: relative
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .chatter_chat .senting_massage .chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    color: #333;
    padding: 0 2px;
    position: absolute;
    bottom: 3px;
    right: 7px
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .chatter_chat .massage_D {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: -90px;
    z-index: 5;
    opacity: 0
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .chatter_chat .massage_D ul li {
    display: inline-block;
    margin: 0 4px;
    height: 34px;
    width: 34px;
    background-color: rgba(3, 40, 141, .1568627451);
    text-align: center;
    border-radius: 50%;
    cursor: pointer
}

.chat .chatter_box .chat_right .chat_body .chat_massage_item .chatter_chat .massage_D ul li i {
    line-height: 35px;
    font-size: 14px
}

.chat .chatter_box .chat_right .chat_body .own_massage_item {
    width: 70%;
    margin: 0 0 0 auto
}

.chat .chatter_box .chat_right .chat_body .own_massage_item .chatter_self_chat {
    text-align: end;
    min-width: 120px
}

.chat .chatter_box .chat_right .chat_body .own_massage_item .chatter_self_chat .senting_massage {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    border-bottom-left-radius: 6px;
    padding: 6px 7px 26px 8px;
    display: inline-block;
    background-image: linear-gradient(to right, #7d6be0, #8367da, #8964d4, #8e60cd, #925dc7);
    font-size: 14px;
    line-height: 22px;
    font-weight: 400;
    color: #fff;
    position: relative;
    text-align: start
}

.chat .chatter_box .chat_right .chat_body .own_massage_item .chatter_self_chat .senting_massage .chat_timing {
    font-size: 11px;
    font-weight: 400;
    display: inline-block;
    color: #d9d8d8;
    position: absolute;
    bottom: 3px;
    left: 7px
}

.chat .chatter_box .chat_right .chating_path {
    height: 40px;
    width: 95%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border: 2px solid rgba(123, 106, 224, .7019607843);
    position: absolute;
    bottom: 15px;
    left: 50%;
    transform: translateX(-50%);
    border-radius: 35px
}

.chat .chatter_box .chat_right .chating_path .path_side_1 {
    width: 10%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul {
    padding-top: 3px
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul li {
    display: inline-block;
    margin-left: 5px;
    cursor: pointer
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul li i {
    font-size: 22px;
    color: #888;
    display: inline-block;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    width: 22px
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul .add_clip {
    position: relative
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul .add_clip .profileDahsed .sub_down5 {
    display: none;
    background-color: #fff;
    padding: 10px 10px 10px 10px;
    box-shadow: 0px .532px 1px 1px rgba(0, 0, 0, .2);
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    overflow: hidden;
    width: 185px;
    border: none !important;
    padding: 0;
    z-index: 9;
    position: absolute;
    left: 0;
    top: -180px
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul .add_clip .profileDahsed .sub_down5 li {
    padding: 8px 12px;
    width: 100%;
    margin-left: 0px
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul .add_clip .profileDahsed .sub_down5 li:hover {
    background-color: rgba(125, 107, 224, .3294117647);
    width: 100%
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul .add_clip .profileDahsed .sub_down5 li a {
    font-size: 14px;
    font-weight: 400;
    color: #000
}

.chat .chatter_box .chat_right .chating_path .path_side_1 ul .add_clip .profileDahsed .sub_down5 li i {
    font-size: 15px;
    color: #888
}

.chat .chatter_box .chat_right .chating_path .path_side_2 {
    width: 80%;
    height: 40px
}

.chat .chatter_box .chat_right .chating_path .path_side_2 .form_control {
    width: 100%;
    height: 40px;
    padding: 5px;
    color: #333;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0);
    background-clip: padding-box;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.chat .chatter_box .chat_right .chating_path .path_side_2 .form_control:focus {
    outline: 0
}

.chat .chatter_box .chat_right .chating_path .path_side_2 .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.chat .chatter_box .chat_right .chating_path .path_side_3 {
    width: 10%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center
}

.chat .chatter_box .chat_right .chating_path .path_side_3 ul li {
    display: inline-block;
    cursor: pointer;
    padding-top: 3px
}

.chat .chatter_box .chat_right .chating_path .path_side_3 ul li i {
    font-size: 22px;
    color: #888;
    display: inline-block;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%)
}

.chat .chatter_box .chat_right .chating_path .path_side_3 ul .send_btn {
    height: 28px;
    width: 28px;
    font-size: 17px;
    color: #fff;
    background-color: #7d6be0;
    text-align: center;
    line-height: 30px;
    border-radius: 50%;
    cursor: pointer
}

.chat .chatter_box .chatter_info {
    display: none;
    width: 40%;
    height: 85vh;
    padding: 15px 12px;
    background-color: #fff;
    border-top-left-radius: 22px;
    border-bottom-left-radius: 22px;
    border-left: 1px solid #e5e2e2
}

.chat .chatter_box .chatter_info .info_header {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    padding-bottom: 30px;
    display: inline-block;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%)
}

.chat .chatter_box .chatter_info .informaqtion_1 h4 {
    font-size: 17px;
    color: #333;
    font-weight: 500
}

.chat .chatter_box .chatter_info .informaqtion_1 ul {
    padding: 0 15px
}

.chat .chatter_box .chatter_info .informaqtion_1 ul li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 3px
}

.chat .chatter_box .chatter_info .informaqtion_1 ul li .data_header {
    font-size: 15px;
    color: #333;
    font-weight: 400
}

.chat .chatter_box .chatter_info .informaqtion_1 ul li .data_ans {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.chat .chatter_box .chatter_info .info_accordion {
    margin-top: 20px
}

.chat .chatter_box .chatter_info .info_accordion .accordion .accordion-item {
    background-color: rgba(0, 0, 0, 0) !important
}

.chat .chatter_box .chatter_info .info_accordion .accordion .accordion-item .accordion-button {
    background-color: rgba(0, 0, 0, 0);
    padding: 10px;
    font-size: 15px;
    color: #333;
    font-weight: 500
}

.chat .chatter_box .chatter_info .info_accordion .accordion .accordion-item .accordion-button:focus {
    border-color: #b8b8b8;
    box-shadow: 0 0 0 .25rem rgba(0, 0, 0, 0)
}

.chat .chatter_box .chatter_info .info_accordion .accordion .accordion-item .accordion-body {
    padding: 10px
}

.chat .chatter_box .chatter_info .info_accordion .accordion .accordion-item .accordion-body ul li {
    margin: 4px 0;
    background-color: rgba(3, 40, 141, .062745098);
    padding: 5px 10px;
    cursor: pointer;
    font-size: 15px
}

.chat .ChatBox-newclass .chat_right {
    width: 60%;
    transition: all linear .3s
}

.chat .ChatBox-newclass .chat_right .chating_path .path_side_1 {
    width: 8%;
    justify-content: end
}

.chat .ChatBox-newclass .chat_right .chating_path .path_side_2 {
    width: 72%
}

.chat .ChatBox-newclass .chat_right .chating_path .path_side_3 {
    width: 20%
}

.chat .ChatBox-newclass .chatter_info {
    display: block;
    transition: all linear .3s
}

.notification .notification_list {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    height: 720px;
    border-radius: 13px;
    padding: 15px;
    overflow: hidden
}

.notification .notification_list .notification_top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px
}

.notification .notification_list .notification_top .notification_top_left {
    font-size: 20px;
    color: #333;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%);
    font-weight: 500
}

.notification .notification_list .notification_top .notification_top_right .nav .nav-link {
    margin-left: 15px;
    display: block;
    font-size: 16px;
    padding: 0px;
    color: #333
}

.notification .notification_list .notification_body {
    margin-top: 20px
}

.notification .notification_list .notification_body .notification_item {
    height: 105px;
    width: 100%;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 7px;
    padding: 12px;
    margin-top: 10px
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 {
    display: flex;
    align-items: center
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_img {
    height: 40px;
    width: 40px;
    border-radius: 50%;
    overflow: hidden
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info {
    padding-left: 6px;
    width: 100%
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul {
    display: flex;
    justify-content: space-between
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul li {
    font-size: 13px;
    color: #888;
    font-size: 400
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul li .profile_user_name {
    font-size: 17px;
    color: #333;
    font-weight: 500
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul .post_time {
    color: #7d6be0
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info ul .post_time i {
    font-size: 13px;
    cursor: pointer
}

.notification .notification_list .notification_body .notification_item .profile_bio_2 .profile_info .profile_title {
    font-size: 12px;
    line-height: 14px;
    color: #7d6be0;
    font-weight: 500
}

.notification .notification_list .notification_body .notification_item .notify_text {
    font-size: 13px;
    line-height: 18px;
    color: #888;
    text-align: justify
}

.notfound_wrapper {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999
}

.notfound_wrapper .errorImg {
    height: 480px;
    width: auto
}

.notfound_wrapper .errorImg img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.notfound_wrapper .cheking_div {
    font-size: 20px;
    color: #333;
    font-weight: 500
}

.search_for_commuinityBody {
    padding: 15px;
    background-color: #fff;
    border-radius: 13px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    height: 900px
}

.search_for_commuinityBody .search_for_commuinity {
    max-width: 500px;
    margin: 0 auto
}

.search_for_commuinityBody .search_for_commuinity form {
    margin-top: 20px
}

.search_for_commuinityBody .search_for_commuinity form .searchBar {
    position: relative;
    width: 100%
}

.search_for_commuinityBody .search_for_commuinity form .searchBar .form_control {
    display: block;
    width: 100%;
    height: 60px;
    padding: 0 25px;
    color: #333;
    border: 1px solid #d5d5d5;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.search_for_commuinityBody .search_for_commuinity form .searchBar .form_control:focus {
    outline: 0
}

.search_for_commuinityBody .search_for_commuinity form .searchBar .form_control::placeholder {
    font-size: 16px;
    color: #aeacac;
    font-weight: 400
}

.search_for_commuinityBody .search_for_commuinity form .searchBar .form_control:optional {
    color: #636262
}

.search_for_commuinityBody .search_for_commuinity form .searchBar i {
    font-size: 20px;
    color: #333;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-left: 1px solid #b5b5b5;
    padding: 0 10px;
    cursor: pointer
}

.search_for_commuinityBody .commuinity_list {
    background-color: #ecebf6;
    padding: 10px;
    border-radius: 10px;
    margin-top: 15px;
    border: 1px solid #d5d5d5
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top {
    display: flex
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .comuinity_img {
    height: 70px;
    width: 70px;
    border-radius: 6px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .comuinity_img img {
    max-width: 100%;
    max-height: 100%;
    border-radius: 6px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .community_text {
    padding-left: 10px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .community_text .community_name {
    font-size: 17px;
    font-weight: 500;
    color: #888;
    line-height: 22px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_top .community_text .commuinity_hint {
    font-size: 13px;
    font-weight: 400;
    color: rgba(51, 51, 51, .6588235294);
    line-height: 18px
}

.search_for_commuinityBody .commuinity_list .commuinity_list_bottom {
    padding: 8px;
    background-color: rgba(123, 106, 224, .7019607843);
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 8px;
    cursor: pointer
}

.search_for_commuinityBody .commuinity_list .commuinity_list_bottom:hover {
    background-color: #7d6be0;
    color: #fff
}

.MoreComuinity_list {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    border-radius: 10px;
    padding: 15px;
    margin-bottom: 12px
}

.MoreComuinity_list .list_heading {
    font-size: 16px;
    font-weight: 500;
    color: #333
}

.MoreComuinity_list .commuinity_list {
    background-color: #ecebf6;
    padding: 10px;
    border-radius: 10px;
    margin-top: 10px;
    border: 1px solid #d5d5d5
}

.MoreComuinity_list .commuinity_list h5 {
    text-align: center;
    color: rgba(0, 0, 0, .26)
}

.MoreComuinity_list .commuinity_list .commuinity_list_top {
    display: flex
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .comuinity_img {
    height: 55px;
    width: 55px
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .comuinity_img img {
    width: 53px;
    height: 53px;
    border-radius: 6px;
    object-fit: cover
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .community_text {
    padding-left: 10px
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .community_text .community_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 18px
}

.MoreComuinity_list .commuinity_list .commuinity_list_top .community_text .commuinity_hint {
    font-size: 12px;
    font-weight: 400;
    color: rgba(51, 51, 51, .6588235294);
    line-height: 18px
}

.MoreComuinity_list .commuinity_list .commuinity_list_bottom {
    height: 36px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    text-align: center;
    color: #fff;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #d5d5d5;
    background-color: #7d6be0;
    margin-top: 10px;
    cursor: pointer
}

.MoreComuinity_list .commuinity_list .commuinity_list_bottom:hover {
    background-color: #6050b5;
    transition: all linear .2s
}

.MoreComuinity_list .commuinity_list .job_createBtn {
    width: 100%;
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    font-size: 14px;
    background-color: #fff;
    margin-top: 12px
}

.MoreComuinity_list .seeMore_btn {
    text-align: center;
    font-size: 16px;
    color: #888;
    font-weight: 500;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #d5d5d5;
    margin-top: 12px;
    cursor: pointer
}

.MoreComuinity_list .seeMore_btn:hover {
    background-color: #ecebf6
}

.CartEmpty_body {
    text-align: center;
    position: absolute;
    top: 40%;
    left: 50%;
    width: 90%;
    transform: translate(-50%, -40%)
}

.CartEmpty_body .cartingIcon {
    height: 85px;
    width: 85px;
    background-color: rgba(216, 189, 252, .6039215686);
    border-radius: 50%;
    text-align: center;
    margin: 0 auto
}

.CartEmpty_body .cartingIcon i {
    font-size: 34px;
    line-height: 85px;
    color: rgba(123, 106, 224, .7019607843)
}

.CartEmpty_body .empty_massage {
    font-size: 20px;
    font-weight: 500;
    color: #333;
    padding: 12px 0 5px 0
}

.CartEmpty_body p {
    font-size: 15px;
    color: #888;
    font-weight: 400;
    line-height: 22px;
    text-align: center
}

.ConnectWith {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    border-radius: 10px;
    padding: 15px
}

.ConnectWith .list_heading {
    font-size: 16px;
    font-weight: 500;
    color: #333;
    padding-bottom: 5px
}

.ConnectWith .connectWith_item {
    height: 100px;
    background-color: #ecebf6;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    padding: 10px;
    margin-top: 8px;
    position: relative
}

.ConnectWith .connectWith_item h5 {
    text-align: center;
    color: rgba(0, 0, 0, .26)
}

.ConnectWith .connectWith_item .job_createBtn {
    width: 100%;
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #888;
    font-size: 14px;
    background-color: #fff;
    margin-top: 12px
}

.ConnectWith .connectWith_item .item_content {
    display: flex
}

.ConnectWith .connectWith_item .item_content .connectWith_left {
    height: 60px;
    width: 60px
}

.ConnectWith .connectWith_item .item_content .connectWith_left img {
    height: 57px;
    width: 57px;
    object-fit: cover;
    border-radius: 6px
}

.ConnectWith .connectWith_item .item_content .connectWith_right {
    padding-left: 10px
}

.ConnectWith .connectWith_item .item_content .connectWith_right .connector_name {
    font-size: 14px;
    font-weight: 500;
    color: #333;
    line-height: 18px
}

.ConnectWith .connectWith_item .item_content .connectWith_right .connectro_commuinity_name {
    font-size: 12px;
    font-weight: 400;
    color: #888;
    line-height: 18px
}

.ConnectWith .connectWith_item .item_content .connectButtonGroup {
    display: flex;
    padding-top: 5px
}

.ConnectWith .connectWith_item .item_content .connectButtonGroup .Connect_btn {
    padding: 0 14px;
    height: 26px;
    line-height: 26px;
    background-color: #7d6be0;
    text-align: center;
    font-size: 12px;
    color: #fff;
    font-weight: 400;
    border-radius: 15px;
    cursor: pointer;
    margin-right: 5px
}

.ConnectWith .connectWith_item .item_content .connectButtonGroup .notConnect_btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: rgba(226, 15, 15, .479);
    text-align: center;
    cursor: pointer;
    margin-left: 6px
}

.ConnectWith .connectWith_item .item_content .connectButtonGroup .notConnect_btn i {
    font-size: 16px;
    color: #888;
    line-height: 30px
}

.ConnectWith .seeMore_btn {
    text-align: center;
    font-size: 16px;
    color: #888;
    font-weight: 500;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 8px;
    padding: 8px;
    border: 1px solid #d5d5d5;
    margin-top: 12px;
    cursor: pointer
}

.ConnectWith .seeMore_btn:hover {
    background-color: #ecebf6
}

.userCard {
    height: 300px;
    width: 100%;
    background-color: #fff;
    border-radius: 13px;
    margin-bottom: 20px;
    border: 1px solid #d5d5d5;
    padding: 12px;
    position: relative
}

.userCard .userProfile {
    height: 140px;
    width: 140px;
    border-radius: 50%;
    margin: 0 auto
}

.userCard .userProfile img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
    border: 5px solid #ecebf6
}

.userCard .userCard_content {
    padding-top: 10px
}

.userCard .userCard_content .userName {
    font-size: 17px;
    color: #333;
    font-weight: 500;
    line-height: 22px
}

.userCard .userCard_content .userDesignation {
    font-size: 12px;
    color: #888;
    font-weight: 400;
    line-height: 16px;
    display: inline-block;
    padding: 4px 18px;
    background-color: #ecebf6;
    border-radius: 15px;
    margin: 4px 0
}

.userCard .userCard_content .communityName {
    font-size: 13px;
    color: #888;
    font-weight: 400;
    line-height: 16px;
    padding-top: 2px
}

.userCard .userCard_content .userCard_footerBtn {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    bottom: 12px;
    left: 0;
    width: 100%;
    padding: 0 10px
}

.userCard .userCard_content .userCard_footerBtn .CardBtn_1 {
    height: 38px;
    width: 70%;
    background-color: #7d6be0;
    color: #fff;
    text-align: center;
    line-height: 38px;
    border-radius: 8px;
    font-size: 15px;
    cursor: pointer
}

.userCard .userCard_content .userCard_footerBtn .CardBtn_2 {
    height: 38px;
    width: 25%;
    background-color: rgba(3, 40, 141, .062745098);
    color: #fff;
    text-align: center;
    line-height: 38px;
    border-radius: 8px;
    cursor: pointer
}

.userCard .userCard_content .userCard_footerBtn .CardBtn_2 i {
    color: #888;
    font-size: 19px
}

.PhotoAdding_Body {
    width: 100%;
    border: 1px solid #d5d5d5;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 10px;
    position: relative;
    cursor: pointer;
    padding: 10px
}

.PhotoAdding_Body .AddPLaceHolder {
    text-align: center
}

.PhotoAdding_Body .AddPLaceHolder .AddPlace_icon {
    height: 80px;
    width: 80px;
    background-color: #ecebf6;
    border-radius: 50%;
    text-align: center;
    margin: 0 auto
}

.PhotoAdding_Body .AddPLaceHolder .AddPlace_icon i {
    font-size: 30px;
    color: #888;
    line-height: 80px
}

.PhotoAdding_Body .AddPLaceHolder h4 {
    font-size: 16px;
    font-weight: 400;
    color: #333;
    padding-top: 6px
}

.PhotoAdding_Body .AddPLaceHolder h6 {
    font-size: 14px;
    font-weight: 400;
    color: rgba(94, 94, 94, .7254901961);
    line-height: 10px
}

.AddingLIst .AddingList_item {
    height: 50px;
    width: 50px;
    background-color: #ecebf6;
    border-radius: 5px
}

.modal-footer {
    justify-content: flex-start
}

.uploader .backdrop_contorller {
    z-index: 6
}

.my_files_navigate {
    height: 50px;
    width: 100%;
    background-color: #fff;
    padding: 0 12px;
    border-bottom-left-radius: 7px;
    border-bottom-right-radius: 7px;
    display: flex;
    align-items: center;
    justify-content: space-between
}

.my_files_navigate .navigate_part_one {
    display: flex;
    align-items: center
}

.my_files_navigate .navigate_part_one .navigate_Back {
    display: flex;
    align-items: center;
    color: #333;
    font-size: 14px;
    padding-right: 20px;
    cursor: pointer
}

.my_files_navigate .navigate_part_one .navigate_bar_icon {
    display: flex;
    align-items: center;
    justify-content: center
}

.my_files_navigate .navigate_part_one .navigate_bar_icon .MuiSvgIcon-root {
    font-size: 26px;
    color: #888;
    cursor: pointer
}

.my_files_navigate .navigate_part_one .navigate_bar_icon .MuiSvgIcon-root:hover {
    color: #333
}

.my_files_navigate .navigate_part_one .navigate_title {
    font-size: 14px;
    color: #888;
    font-weight: 400;
    padding: 0 0 0 15px;
    cursor: pointer
}

.my_files_navigate .navigate_part_two .navigate_serachbar {
    height: 35px;
    width: 200px;
    position: relative
}

.my_files_navigate .navigate_part_two .navigate_serachbar .form_control {
    background-clip: padding-box;
    background-color: #f3f3f3;
    border: 1px solid #d5d5d5;
    border-radius: 8px;
    color: #333;
    height: 35px;
    padding: 0 10px 0 10px;
    position: relative;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    width: 100%;
    font-size: 18px
}

.my_files_navigate .navigate_part_two .navigate_serachbar .form_control::placeholder {
    color: #aba8a8;
    font-size: 15px;
    font-weight: 400
}

.Media_area {
    padding-top: 20px
}

.my_item {
    height: 220px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 10px;
    position: relative
}

.my_item .item_icon {
    height: 90px;
    width: 90px;
    display: flex;
    align-items: center
}

.my_item .item_icon img {
    max-width: 100%;
    max-height: 100%
}

.my_item .myItemBookmark {
    position: absolute;
    top: 12px;
    right: 12px
}

.my_item .myItemBookmark .MuiSvgIcon-root {
    font-size: 20px;
    color: #888;
    cursor: pointer
}

.my_item .myItemBookmark .MuiSvgIcon-root:hover {
    color: #7d6be0
}

.my_item .item_name {
    font-size: 16px;
    color: #333;
    font-weight: 400;
    line-height: 22px;
    padding: 10px 0 6px 0
}

.my_item .my_item_bootom {
    height: 40px;
    width: 100%;
    background-color: #ecebf6;
    position: absolute;
    bottom: 0;
    left: 0;
    padding: 10px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px
}

.my_item .my_item_bootom .uploaded_date {
    font-size: 12px;
    color: #333;
    font-weight: 400
}

.my_item .Folder_list_activity {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 40px;
    background-color: #ecebf6;
    width: 100%;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    justify-content: end
}

.HomeBodyContent {
    padding: 15px 0 15px 0
}

.HomeBodyContent .store_Card {
    height: 130px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 14px;
    position: relative;
    overflow: hidden;
    cursor: pointer
}

.HomeBodyContent .store_Card:hover {
    box-shadow: 0px 10px 10px rgba(1, 6, 20, .1)
}

.HomeBodyContent .store_Card .store_Card_top .overviewItem_icon {
    height: 45px;
    width: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #ecebf6;
    border-radius: 10px
}

.HomeBodyContent .store_Card .store_Card_top .overviewItem_icon img {
    max-width: 60%;
    max-height: 60%
}

.HomeBodyContent .store_Card .store_Card_top .card_name {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    padding-top: 10px
}

.HomeBodyContent .store_Card .store_Card_top .card_file_quantity {
    font-size: 14px;
    color: #888;
    font-weight: 400
}

.my_file_item {
    height: 220px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 10px;
    padding: 10px;
    position: relative;
    overflow: hidden
}

.my_file_item .item_icon {
    height: 115px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    position: relative;
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
    overflow: hidden
}

.my_file_item .item_icon img {
    width: 100%;
    height: 100%;
    object-fit: contain
}

.my_file_item .item_name {
    font-size: 16px;
    color: #333;
    font-weight: 400;
    line-height: 22px;
    padding: 10px 0 6px 0;
    word-wrap: break-word
}

.my_file_item .my_item_bootom {
    height: 38px;
    width: 100%;
    background-color: #ecebf6;
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: flex;
    align-items: center;
    padding-left: 10px
}

.my_file_item .my_item_bootom .uploaded_date {
    font-size: 12px;
    color: #333;
    font-weight: 400
}

.my_file_item .File_list_activity {
    position: absolute;
    bottom: 8px;
    right: 3px;
    z-index: 2
}

.my_file_item .File_list_activity i .MuiSvgIcon-root {
    color: rgba(75, 78, 81, .718);
    font-size: 20px;
    cursor: pointer
}

.my_file_item .File_list_activity i .MuiSvgIcon-root:hover {
    color: #333
}

.upload_dialouge_title {
    position: relative;
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #333
}

.upload_dialouge_title .uploader_dialogue_close {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #888;
    cursor: pointer
}

.upload_dialouge_title .uploader_dialogue_close:hover {
    color: #333
}

.upload_dialouge_body {
    width: 500px
}

.upload_dialouge_body .uploader_Content {
    padding: 20px;
    width: 100%
}

.upload_dialouge_body .uploader_Content .uploader_icon {
    height: 100px;
    width: 100px;
    background-color: #ecebf6;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto
}

.upload_dialouge_body .uploader_Content .uploader_icon img {
    height: 55px;
    width: 55px;
    object-fit: contain
}

.upload_dialouge_body .uploader_Content h4 {
    font-size: 18px;
    color: #888;
    font-weight: 500;
    padding-top: 10px
}

.homeMainContent {
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

.homeMainContent .backArrow {
    height: 38px;
    width: 38px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 20px;
    color: #888;
    cursor: pointer
}

.homeMainContent .backArrow:hover {
    color: #333
}

.homeMainContent .login_D_form {
    height: 500px;
    box-shadow: 0px 1px 1px 1px rgba(101, 101, 101, .1294117647);
    background-color: #fff;
    display: flex;
    justify-content: space-between;
    border-radius: 10px;
    overflow: hidden
}

.homeMainContent .login_D_form .login_d_left {
    height: 100%;
    width: 450px;
    padding: 20px 20px 15px 20px;
    position: relative
}

.homeMainContent .login_D_form .login_d_left .commuinity_name {
    text-align: center;
    font-size: 20px;
    font-weight: 700;
    color: #333
}

.homeMainContent .login_D_form .login_d_left .Community_img {
    height: 140px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 12px 0
}

.homeMainContent .login_D_form .login_d_left .Community_img img {
    max-height: 100%;
    max-width: 100%
}

.homeMainContent .login_D_form .login_d_left .community_location {
    font-size: 14px;
    color: #888;
    font-weight: 400;
    display: flex;
    align-items: center
}

.homeMainContent .login_D_form .login_d_left .community_location .MuiSvgIcon-root {
    color: #7d6be0;
    font-size: 20px
}

.homeMainContent .login_D_form .login_d_left .tag_line {
    font-size: 20px;
    font-weight: 600;
    color: #333;
    line-height: 28px;
    padding: 10px 0 5px 0
}

.homeMainContent .login_D_form .login_d_left p {
    font-size: 14px;
    color: #888;
    line-height: 22px;
    text-align: start;
    font-weight: 400
}

.homeMainContent .login_D_form .login_d_left .commnity_contactor {
    font-size: 15px;
    color: #888;
    font-weight: 400
}

.homeMainContent .login_D_form .login_d_left .createCommuinity {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 20px
}

.homeMainContent .login_D_form .login_d_left .createCommuinity .createCommunityBtn {
    font-size: 14px;
    font-weight: 500;
    height: 40px;
    border: 1px solid #d5d5d5;
    border-radius: 6px;
    text-align: center;
    color: #888;
    display: flex;
    justify-content: center;
    align-items: center
}

.homeMainContent .login_D_form .login_d_left .createCommuinity .createCommunityBtn:hover {
    background-color: #f3f3f3;
    color: #333
}

.homeMainContent .login_D_form .login_d_right {
    height: 500px;
    width: 360px;
    background-color: #7d6be0;
    padding: 30px 30px 15px 30px
}

.homeMainContent .login_D_form .login_d_right h6 {
    font-size: 24px;
    color: #fff;
    font-weight: 500;
    text-align: center
}

.homeMainContent .login_D_form .login_d_right .login_bday {
    padding-top: 22px
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed {
    position: relative
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .form_control {
    font-size: 16px;
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid rgba(173, 173, 184, .574);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .form_control:focus {
    outline: 0
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .form_control::placeholder {
    font-size: 15px;
    color: #aeacac;
    font-weight: 400
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .form_control:optional {
    color: #636262
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root {
    background-color: #fff;
    border-radius: 5px;
    border: rgba(0, 0, 0, 0) !important
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1.4375em;
    padding: 13px 10px !important;
    padding-right: 0
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root {
    outline: 0
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:hover {
    border-color: rgba(0, 0, 0, 0) !important
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:focus {
    outline: 0 !important
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save .pass_left {
    display: flex;
    align-items: center;
    font-size: 15px;
    color: #fff
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save .pass_right {
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_pass_save .pass_right:hover {
    color: #333
}

.homeMainContent .login_D_form .login_d_right .login_bday form .login_btn {
    margin-top: 15px;
    background-color: #f86a65;
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    line-height: 45px;
    cursor: pointer
}

.homeMainContent .login_D_form .login_d_right .login_bday form .loading_cercle {
    width: 100%;
    display: flex;
    justify-content: center
}

.homeMainContent .login_D_form .login_d_right .login_bday form h3 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    cursor: pointer
}

.homeMainContent .login_D_form .login_d_right .login_bday form h3:hover {
    color: #e3e2e2
}

.homeMainContent .login_D_form .login_d_right .registerBody {
    padding-top: 12px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed {
    position: relative
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .form_control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid rgba(173, 173, 184, .574);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .form_control:focus {
    outline: 0
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .form_control::placeholder {
    font-size: 15px;
    color: #aeacac;
    font-weight: 400
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .form_control:optional {
    color: #636262
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root {
    background-color: #fff;
    border-radius: 5px;
    border: rgba(0, 0, 0, 0) !important
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    height: 1.4375em;
    padding: 13px 10px !important;
    padding-right: 0
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root {
    outline: 0
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:hover {
    border-color: rgba(0, 0, 0, 0) !important
}

.homeMainContent .login_D_form .login_d_right .registerBody form .loginInput_filed .MuiFormControl-root .MuiInputBase-root:focus {
    outline: 0 !important
}

.homeMainContent .login_D_form .login_d_right .registerBody form .emailBody {
    padding-top: 40px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 2px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save .pass_left {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #fff
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save .pass_right {
    font-size: 14px;
    color: #fff;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5
}

.homeMainContent .login_D_form .login_d_right .registerBody form .login_pass_save .pass_right:hover {
    color: #333
}

.homeMainContent .login_D_form .login_d_right .registerBody form .agrrementCheq {
    padding-top: 5px;
    color: #fff;
    font-weight: 400;
    font-size: 14px;
    display: flex;
    align-items: center
}

.homeMainContent .login_D_form .login_d_right .registerBody form .agrrementCheq .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 2px
}

.homeMainContent .login_D_form .login_d_right .registerBody form .get_emailBtn {
    margin-top: 10px;
    background-color: #f86a65;
    height: 45px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    line-height: 45px;
    cursor: pointer
}

.homeMainContent .login_D_form .login_d_right .registerBody form h3 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    cursor: pointer
}

.homeMainContent .login_D_form .login_d_right .registerBody form h3:hover {
    color: #e3e2e2
}

.homeMainContent .login_D_form .login_d_right .registerBody .coverImg {
    display: flex;
    justify-content: center;
    margin: 10px auto
}

.homeMainContent .login_D_form .login_d_right .registerBody .coverImg img {
    height: 90px;
    width: 90px;
    object-fit: contain;
    border-radius: 50%;
    border: 2px solid #fff
}

.loginPowerd {
    position: absolute;
    bottom: 20px;
    right: 50px;
    font-size: 16px;
    color: #888;
    font-weight: 400;
    font-family: "Open Sans", sans-serif
}

.creatCommuinity_form_body {
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative
}

.creatCommuinity_form_body .backArrow {
    height: 38px;
    width: 38px;
    background-color: #fff;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 20px;
    top: 20px;
    color: #888;
    cursor: pointer
}

.creatCommuinity_form_body .backArrow:hover {
    color: #333
}

.creatCommuinity_form_body .creatCommuinity_wrapper {
    background-color: #fff;
    padding: 30px 30px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px
}

.creatCommuinity_form_body .creatCommuinity_wrapper h5 {
    font-size: 24px;
    color: #333;
    font-weight: 500;
    text-align: center
}

.creatCommuinity_form_body .creatCommuinity_wrapper h6 {
    font-size: 15px;
    font-weight: 400;
    color: #888;
    text-align: center
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap {
    width: 500px;
    padding-top: 12px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: #ebebeb;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control:focus {
    outline: 0
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control::placeholder {
    font-size: 15px;
    color: #818181;
    font-weight: 400
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .form_control:optional {
    color: #3a3a3a
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .agrrementCheq {
    padding-top: 5px;
    font-size: 15px;
    color: #888
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .agrrementCheq .chequeB {
    height: 16px;
    width: 16px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form .login_btn {
    background-color: #7d6be0;
    height: 50px;
    width: 100%;
    color: #fff;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    border-radius: 5px;
    line-height: 50px;
    cursor: pointer;
    margin-top: 5px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form h3 {
    font-size: 15px;
    color: #fff;
    font-weight: 400;
    text-align: center;
    font-family: "Roboto", sans-serif;
    cursor: pointer;
    margin-top: 12px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap form h3:hover {
    color: #333
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap textarea {
    min-height: 100px !important
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap .pass_left {
    display: flex;
    align-items: center;
    padding-left: 10px;
    margin-top: 20px;
    font-size: 14px;
    color: #888
}

.creatCommuinity_form_body .creatCommuinity_wrapper .creatCommuinity_wrap .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 10px
}

.creatCommuinity_form_body .creatCommuinity_wrapper .CreateForm_lg {
    width: 700px
}

.genarel_login_bday {
    padding: 20px;
    height: 460px;
    width: 350px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 12px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%)
}

.genarel_login_bday form .login_img {
    height: 45px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 10px 0 15px 0
}

.genarel_login_bday form .login_img img {
    height: 45px;
    width: auto
}

.genarel_login_bday form h4 {
    font-size: 22px;
    font-weight: 500;
    color: #333;
    line-height: 27px;
    text-align: center;
    padding-bottom: 12px
}

.genarel_login_bday form .loginInput_filed {
    position: relative
}

.genarel_login_bday form .loginInput_filed .form_control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 10px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 5px;
    border: 1px solid rgba(173, 173, 184, .574);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    margin-top: 4px
}

.genarel_login_bday form .loginInput_filed .form_control:focus {
    outline: 0
}

.genarel_login_bday form .loginInput_filed .form_control::placeholder {
    font-size: 15px;
    color: #aeacac;
    font-weight: 400
}

.genarel_login_bday form .loginInput_filed .form_control:optional {
    color: #636262
}

.genarel_login_bday form .login_pass_save {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 5px
}

.genarel_login_bday form .login_pass_save .pass_left {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: #888
}

.genarel_login_bday form .login_pass_save .pass_left .chequeB {
    height: 16px;
    width: 16px;
    margin-right: 3px
}

.genarel_login_bday form .login_pass_save .pass_right {
    font-size: 14px;
    color: #888;
    cursor: pointer;
    border-bottom: 1px solid #d5d5d5
}

.genarel_login_bday form .login_pass_save .pass_right:hover {
    color: #333
}

.genarel_login_bday form .login_loader {
    width: 100%;
    display: flex;
    justify-content: center
}

.genarel_login_bday form h3 {
    font-size: 15px;
    color: #888;
    font-weight: 400;
    text-align: center;
    cursor: pointer;
    margin-top: 26px
}

.genarel_login_bday form h3:hover {
    color: #333
}

.copy_right {
    position: absolute;
    bottom: 0;
    left: 0;
    color: #888;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 15px
}

.Home_copy_right {
    position: fixed;
    bottom: 0;
    left: 0;
    color: #888;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    font-size: 15px
}

.uploadBox {
    width: 120px;
    height: 120px;
    background: rgba(0, 0, 0, .02);
    margin: .5em auto;
    position: relative;
    border-radius: 10px;
    border: 1px dashed #d9d9d9
}

.uploadBox:hover {
    background: #ecebf6
}

.uploadBox input {
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer
}

.uploadBox h1 {
    text-align: center;
    color: #333;
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    font-weight: 400
}

.image_place_container {
    height: 120px;
    width: 120px;
    border-radius: 7px;
    background-color: #f3f3f3;
    position: relative;
    margin: 0 auto
}

.image_place_container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 7px
}

.image_place_container .img_place_overly {
    height: 100%;
    width: 100%;
    background-color: rgba(0, 0, 0, .3);
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 7px
}

.image_place_container .img_place_overly .img_cross_btn {
    height: 30px;
    width: 30px;
    border-radius: 50%;
    background-color: #fff;
    color: #888;
    position: absolute;
    top: 10px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer
}

.image_place_container .img_place_overly .img_cross_btn .MuiSvgIcon-root {
    font-size: 16px
}

.userCommuinity_list_wrapper {
    padding: 10px
}

.entry_header .entry_header_wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 7px 0
}

.entry_header .entry_header_wrap .entryHeader_logo img {
    height: 60px;
    width: auto
}

.entry_header .entry_header_wrap .entryHeader_right .createBtn {
    height: 46px;
    width: auto;
    padding: 0 40px;
    border: 1px solid #1976d2;
    border-radius: 7px;
    color: #1976d2;
    font-size: 16px;
    font-weight: 500;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff
}

.entryWrapper {
    width: 100%;
    min-height: 85vh;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px 0;
    position: relative
}

.entryWrapper .entry_wrap {
    width: 100%
}

.entryWrapper .entry_wrap .commuinity_search_form {
    width: 500px;
    margin: 0 auto
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar {
    position: relative;
    width: 100%
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar .form_control {
    display: block;
    width: 100%;
    height: 52px;
    padding: 0 20px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    border: 1px solid #d5d5d5
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar .form_control:focus {
    outline: 0
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar .form_control:optional {
    color: #636262
}

.entryWrapper .entry_wrap .commuinity_search_form .searchBar i {
    font-size: 20px;
    color: #333;
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    border-left: 1px solid #b5b5b5;
    padding: 0 10px;
    cursor: pointer
}

.entryWrapper .entry_wrap .community_list_item {
    padding-top: 40px;
    padding-bottom: 40px
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item {
    height: 350px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 13px;
    padding: 12px;
    position: relative
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinty_img {
    height: 160px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f3f3f3
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinty_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinity_name {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    line-height: 25px;
    padding-top: 15px;
    text-align: center
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinity_location {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 18px;
    text-align: center;
    padding-top: 5px
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .commuinity_location span {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #333
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .card_footer {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 12px
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .card_footer .viewBtn {
    height: 38px;
    border: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    color: #888;
    cursor: pointer
}

.entryWrapper .entry_wrap .community_list_item .commuinity_item .card_footer .viewBtn:hover {
    background-color: #7d6be0;
    color: #fff;
    transition: all linear .2s
}

.community_list_item {
    padding-top: 40px
}

.community_list_item .commuinity_item {
    height: 350px;
    width: 100%;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 13px;
    padding: 12px;
    position: relative
}

.community_list_item .commuinity_item .commuinty_img {
    height: 160px;
    width: 100%;
    border-radius: 8px;
    overflow: hidden;
    background-color: #f3f3f3
}

.community_list_item .commuinity_item .commuinty_img img {
    height: 100%;
    width: 100%;
    object-fit: cover
}

.community_list_item .commuinity_item .commuinity_name {
    font-size: 18px;
    font-weight: 700;
    color: #333;
    line-height: 25px;
    padding-top: 15px;
    text-align: center
}

.community_list_item .commuinity_item .commuinity_location {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 18px;
    text-align: center;
    padding-top: 5px
}

.community_list_item .commuinity_item .commuinity_location span {
    font-size: 14px;
    font-family: "Roboto", sans-serif;
    font-weight: 500;
    color: #333
}

.community_list_item .commuinity_item .card_footer {
    position: absolute;
    bottom: 12px;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    padding: 0 12px
}

.community_list_item .commuinity_item .card_footer .viewBtn {
    height: 38px;
    border: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
    font-size: 14px;
    color: #888;
    cursor: pointer
}

.community_list_item .commuinity_item .card_footer .viewBtn:hover {
    background-color: #7d6be0;
    color: #fff;
    transition: all linear .2s
}

.btn_loader {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 10px
}

.searchLoading {
    text-align: center;
    font-size: 20px;
    font-weight: 500;
    color: rgba(0, 0, 0, .26)
}

.profileBody .nav {
    background-color: #fff;
    --bs-nav-link-padding-y: 1rem;
    --bs-nav-link-padding-x: 1rem;
    border-radius: 10px
}

.profileBody .nav .nav-item .nav-link {
    margin-left: 12px
}

.profileBody .nav .nav-item .nav-link:hover {
    isolation: isolate;
    border-color: #fff
}

.profileBody .nav .nav-item .active {
    border-bottom: 3px solid #7d6be0 !important
}

.profileBody .my_friend {
    height: 600px;
    width: 100%;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    background-color: #fff;
    border-radius: 13px;
    padding: 15px;
    margin-top: 10px
}

.profileBody .my_friend .myFriend_top {
    display: flex;
    justify-content: space-between;
    align-items: center
}

.profileBody .my_friend .myFriend_top .heading {
    font-size: 18px;
    color: #333;
    font-weight: 500
}

.profileBody .my_friend .myFriend_top .search_box {
    max-width: 210px;
    width: 210px;
    position: relative;
    height: 40px
}

.profileBody .my_friend .myFriend_top .search_box form .form_control {
    margin-bottom: 10px;
    width: 100%;
    height: 40px;
    padding: 12px;
    color: #333;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    border: 1px solid #dededf;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.profileBody .my_friend .myFriend_top .search_box form .form_control:focus {
    outline: 0
}

.profileBody .my_friend .myFriend_top .search_box form .form_control::placeholder {
    font-size: 14px;
    color: #aeacac;
    font-weight: 400
}

.profileBody .my_friend .myFriend_top .search_box form .form_control:optional {
    color: #636262
}

.profileBody .my_friend .myFriend_top .search_box form i {
    font-size: 16px;
    color: #888;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    cursor: pointer
}

.profileBody .my_friend .friend_item_List .friend_item {
    display: flex;
    align-items: center;
    height: 90px;
    background-color: #ecebf6;
    border-radius: 10px;
    border: 1px solid #d5d5d5;
    padding: 10px;
    margin-top: 8px;
    position: relative
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_left {
    height: 55px;
    width: 55px;
    border-radius: 50%
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_left img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_right {
    padding-left: 10px
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_right .friend_name {
    font-size: 17px;
    font-weight: 500;
    color: #333;
    line-height: 22px
}

.profileBody .my_friend .friend_item_List .friend_item .friend_item_right .connectro_commuinity_name {
    font-size: 13px;
    font-weight: 400;
    color: #888;
    line-height: 18px
}

.profileBody .my_friend .friend_item_List .friend_item .frined_droper {
    position: absolute;
    top: 10px;
    right: 10px
}

.profileBody .my_friend .friend_item_List .friend_item .frined_droper .dropdown-toggle {
    background-color: rgba(3, 40, 141, .062745098);
    color: rgba(51, 51, 51, .7725490196)
}

.user_profile .cover-photo {
    height: 250px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px
}

.user_profile .user_profile_content {
    padding: 20px
}

.user_profile .user_profile_content .content_top {
    display: flex
}

.user_profile .user_profile_content .content_top .user_profileImg {
    height: 130px;
    width: 130px;
    margin-top: -65px;
    margin-left: 10px
}

.user_profile .user_profile_content .content_top .user_profileImg img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%
}

.user_profile .user_profile_content .content_top .profile-name {
    padding-left: 10px;
    font-size: 24px;
    font-weight: 600;
    color: #333
}

.user_profile .user_profile_content .content_top .profile-name .userDesignation {
    font-size: 14px;
    color: #333;
    font-weight: 400;
    padding-top: 3px
}

.user_profile .user_profile_content .userAbout {
    border: 1px solid #d5d5d5;
    padding: 10px;
    font-size: 15px;
    color: #333;
    border-radius: 8px;
    line-height: 25px;
    margin-top: 16px
}

.user_profile .user_profile_content .user_icons_list {
    display: flex;
    justify-content: end;
    margin-top: 20px
}

.user_profile .user_profile_content .user_icons_list a {
    margin-left: 12px
}

.user_profile .user_profile_content .user_icons_list a i {
    height: 40px;
    width: 40px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center
}

.user_profile .user_profile_content .user_icons_list a i .MuiSvgIcon-root {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 26px;
    color: #fff
}

.user_profile .user_profile_content .user_icons_list a .linkedin_clr {
    background-color: #0072b1
}

.user_profile .user_profile_content .user_icons_list a .github_clr {
    background-color: #171515
}

.user_profile .user_profile_content .user_icons_list a .youtube_clr {
    background-color: #c4302b
}

.user_profile .user_profile_content .user_icons_list a .twitter_clr {
    background-color: #00acee
}

.user_profile .user_profile_content .user_icons_list a .insta_clr {
    background-color: #e1306c
}

.user_profile .user_profile_content .user_icons_list a .website_clr {
    background-color: #525151
}

.user_profile .user_profile_content .user_icons_list a .whatsapp_clr {
    background-color: #45c755
}

.user_profile .user_profile_content .user_icons_list a .skype_clr {
    background-color: #00aff0;
    color: #fff;
    font-size: 26px
}

.user_dashBoard {
    background-color: #fff;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px
}

.profile-Loader {
    margin: 0 auto
}

.AddingList_item img {
    height: 100%;
    width: 100%
}

.errorComponentImge img {
    margin-left: 300px;
    margin-top: 100px
}

.ant-upload-list-item-error {
    border-color: #0b5ed7 !important
}

.ant-upload-list-item-name {
    color: #0b5ed7 !important
}

.anticon {
    color: #0b5ed7 !important
}

.anticon svg path[fill="#1890ff"] {
    fill: #0b5ed7 !important
}

.jobsearchButtonAndText {
    display: flex;
    align-items: center;
    flex-wrap: wrap
}

.jobsearchButtonAndText .jobsearchButton {
    margin-left: 150px
}

.uploader .backdrop_contorller {
    z-index: 6 !important;
}

.uploader_contianer {
    padding: 10px 10px 20px 10px !important;
    width: 100% !important;
}

.uploader_contianer .folder_name_input {
    width: 100% !important;
    margin-bottom: 12px !important;
}

.uploader_contianer .folder_name_input .css-1x5jdmq {
    min-width: 392px !important
}

.uploader_contianer .Upload_btn {
    width: 100% !important;
    margin-top: 12px !important;
    height: 40px !important;
}

/* .css-lqq9ua {
    display: none !important;
} */

.css-5t8t4o {
    width: 420px !important
}

.css-13ijswq {
    background: #6ba4d1 !important;
    height: 200px !important;
    position: relative !important
}

/* .css-9ufzdr {
    position: absolute !important;
    top: 50% !important;
    left: 50% !important;
    transform: translate(-50%, -50%) !important;
    width: 100% !important;
    margin-top: 0px !important
} */

/* .css-15bzofy img {
    display: none !important
} */

.recycle_img {
    height: 250px;
    width: 250px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: 200px
}

.recycle_img img {
    max-width: 100%;
    max-height: 100%
}

.upload_progress_container {
    width: 300px;
    background-color: #fff;
    height: 70px;
    position: fixed;
    top: 30px;
    right: 0;
    z-index: 1302;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.upload_progress_container .upload_progress {
    width: 300px
}

.upload_progress_container .upload_progress h6 {
    font-size: 16px;
    color: #333;
    font-weight: 500;
    padding-right: 10px;
    text-align: center;
    padding-bottom: 2px
}

.upload_progress_container .upload_progress .MuiBox-root {
    padding: 0 2px 0 4px
}

.upload_progress_container .upload_progress .MuiBox-root .MuiLinearProgress-root {
    height: 10px;
    background-color: #f1efef;
    border-radius: 35px
}

.upload_progress_container .upload_progress .MuiBox-root .MuiLinearProgress-root .MuiLinearProgress-bar {
    background-color: #7d6be0
}

.upload_progress_container .upload_progress .MuiTypography-root {
    color: #333
}

.upload_progress_container .upload_progress .upload_cancle {
    position: absolute;
    right: 10px;
    top: 5px
}

.upload_progress_container .upload_progress .upload_cancle:hover .MuiSvgIcon-root {
    color: #333
}

.upload_progress_container .upload_progress .upload_cancle .MuiSvgIcon-root {
    color: #969393;
    cursor: pointer
}

.swal2-container {
    z-index: 1303 !important
}

/* .css-zq6grw {
    display: none !important
} */

.job_searchBar {
    padding: 15px 15px;
    background-color: #4a9367;
    width: 100%;
    border-radius: 8px;
    margin-bottom: 15px
}

.job_searchBar h4 {
    font-size: 24px;
    color: #fff;
    font-weight: 500
}

.job_searchBar p {
    font-size: 15px;
    color: #e9e9e9;
    font-weight: 400
}

.job_searchBar .job_plate {
    display: flex;
    align-items: center;
    justify-content: space-between
}

.job_searchBar .job_plate li {
    position: relative
}

.job_searchBar .job_plate li i {
    font-size: 15px;
    color: #333;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%)
}

.job_searchBar .job_plate .job_srach_item-1 {
    width: 80%;
    margin-right: 12px
}

.job_searchBar .job_plate .job_srach_item-1 .form_control {
    display: block;
    width: 100%;
    height: 50px;
    padding: 10px;
    color: #333;
    background-color: rgba(255, 255, 255, .4901960784);
    background-clip: padding-box;
    border-radius: 4px;
    border: 1px solid rgba(222, 222, 223, 0);
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -webkit-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -moz-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -ms-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    -o-transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.job_searchBar .job_plate .job_srach_item-1 .form_control:focus {
    outline: 0
}

.job_searchBar .job_plate .job_srach_item-1 .form_control::placeholder {
    font-size: 14px;
    color: #fff;
    font-weight: 400
}

.job_searchBar .job_plate .job_srach_item-1 .form_control:optional {
    color: #636262;
    font-size: 15px;
    padding: 10px
}

.job_searchBar .job_plate .job_btn {
    width: 20%;
    height: 49px;
    background-color: #fff;
    border-radius: 4px;
    text-align: center;
    cursor: pointer
}

.job_searchBar .job_plate .job_btn span {
    line-height: 50px;
    color: #333;
    font-weight: 500
}

.homeJob_cart {
    height: 340px;
    width: 100%;
    background-color: #ecebf6;
    cursor: pointer;
    border-radius: 12px;
    padding: 30px 15px 15px 15px;
    position: relative;
    overflow: hidden;
    border: 1px solid #d5d5d5;
    margin-top: 20px
}

.homeJob_cart:hover {
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.homeJob_cart::after {
    position: absolute;
    content: "";
    top: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background-color: #7d6be0
}

.homeJob_cart .cart_title {
    font-size: 15px;
    color: #7d6be0;
    font-weight: 500;
    text-transform: uppercase;
    display: block;
    
    -webkit-text-fill-color: rgba(0, 0, 0, 0);
    background-image: linear-gradient(to right, #7952b4 0%, #745ce0 30%, #8169f1 30%, #a44cee 73%, #ff847f 100%)
}

.homeJob_cart .cart_heading {
    font-size: 20px;
    color: #333;
    font-weight: 500;
    line-height: 26px
}

.homeJob_cart .candidate {
    font-size: 16px;
    color: #333;
    font-weight: 400;
    padding-top: 15px
}

.homeJob_cart .candidate_activity {
    height: 95px;
    width: 100%;
    background-color: #fff;
    padding: 10px;
    border-radius: 7px;
    border: 1px solid #d5d5d5;
    margin: 8px 0;
    display: flex;
    align-items: center
}

.homeJob_cart .candidate_activity .activity_item {
    width: 50%;
    text-align: center
}

.homeJob_cart .candidate_activity .activity_item .activity_title {
    font-size: 14px;
    color: #888;
    font-weight: 500;
    text-transform: uppercase;
    padding-bottom: 7px
}

.homeJob_cart .candidate_activity .activity_item .activity_Count {
    font-size: 30px;
    font-weight: 700;
    color: #333
}

.homeJob_cart .candidate_activity .activity_item .new_activity {
    color: #7d6be0 !important
}

.homeJob_cart .job_status {
    font-size: 15px;
    color: #888;
    font-weight: 400;
    padding-top: 20px
}

.homeJob_cart .job_status span {
    color: #333;
    font-weight: 500;
    padding-left: 12px
}

.homeJob_cart .cart_footer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #d5d5d5;
    padding: 12px 15px;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%
}

.homeJob_cart .cart_footer .cartFooter_item {
    font-size: 15px;
    color: #888;
    font-weight: 500;
    cursor: pointer
}

.homeJob_cart .cart_footer .cartFooter_item:hover {
    color: #7d6be0
}

.homeJob_cart .cart_footer .cartFooter_item i {
    padding-right: 5px
}

.job_item_info {
    margin: 5px 0;
    background-color: #fff;
    border-radius: 8px;
    height: auto;
    width: 100%;
    padding: 13px 15px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    margin-bottom: 10px;
    position: relative
}

.job_item_info .job_item_info_top {
    display: flex
}

.job_item_info .job_item_info_top .company_logo {
    height: 65px;
    width: 90px;
    cursor: pointer
}

.job_item_info .job_item_info_top .company_logo img {
    max-width: 100%;
    max-height: 100%
}

.job_item_info .job_item_info_top .job_item_infoTop_right {
    margin-left: 10px;
    width: 100%;
    padding-right: 40px
}

.job_item_info .job_item_info_top .job_item_infoTop_right h4 {
    font-size: 17px;
    color: #333;
    font-weight: 600;
    line-height: 20px
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul {
    display: flex
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul li {
    font-size: 14px;
    color: #333;
    font-weight: 500;
    display: flex;
    align-items: center
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul li .MuiSvgIcon-root {
    font-size: 15px;
    color: #5c5c5c;
    margin-right: 3px
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul .workType {
    padding: 1px 15px;
    background-color: rgba(74, 147, 103, .1607843137);
    color: #4a9367;
    font-size: 11px;
    border-radius: 35px;
    margin-left: 10px;
    font-weight: 500
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul .workType .MuiSvgIcon-root {
    color: #4a9367
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul .postedTime {
    font-size: 13px;
    color: #5c5c5c;
    margin-left: 10px;
    display: flex;
    align-items: center;
    font-weight: 400
}

.job_item_info .job_item_info_top .job_item_infoTop_right ul .postedTime .MuiSvgIcon-root {
    font-size: 18px;
    margin-right: 3px
}

.job_item_info .job_item_info_middle {
    cursor: pointer;
    padding-top: 8px
}

.job_item_info .job_item_info_middle p {
    font-size: 13px;
    color: #5c5c5c;
    line-height: 20px
}

.job_item_info .job_fotter {
    display: flex;
    justify-content: space-between;
    align-items: end;
    cursor: pointer
}

.job_item_info .job_fotter ul li {
    font-size: 13px;
    color: #5c5c5c;
    font-weight: 400
}

.job_item_info .job_fotter ul li i {
    font-size: 15px;
    margin-right: 3px
}

.job_item_info .job_fotter .dead-line {
    font-size: 15px;
    color: #333;
    font-weight: 500;
    font-style: normal !important
}

.job_item_info .job_info_btn {
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 2;
    cursor: pointer
}

.JobItemDetails {
    background-color: #fff;
    width: 100%;
    border-radius: 13px;
    padding: 20px 13px 13px 13px;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647)
}

.JobItemDetails .history_item {
    cursor: pointer
}

.JobItemDetails .history_item .job_info {
    display: flex;
    justify-content: space-between
}

.JobItemDetails .history_item .job_info .info_1 {
    width: 68%
}

.JobItemDetails .history_item .job_info .info_1 h4 {
    font-size: 22px;
    color: #333;
    font-weight: 600;
    line-height: 28px;
    text-transform: uppercase
}

.JobItemDetails .history_item .job_info .info_1 h5 {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    line-height: 18px
}

.JobItemDetails .history_item .job_info .info_1 .job_text h6 {
    font-size: 17px;
    color: #333;
    font-weight: 600
}

.JobItemDetails .history_item .job_info .info_2 {
    width: 30%
}

.JobItemDetails .history_item .job_info .info_2 .job_summary {
    width: 100%;
    background-color: rgba(3, 40, 141, .062745098);
    border-radius: 8px;
    border: 1px solid #d5d5d5;
    overflow: hidden
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .summary_title {
    height: 42px;
    width: 100%;
    background-color: rgba(123, 106, 224, .7019607843);
    text-align: center;
    line-height: 42px;
    color: #fff;
    font-weight: 500;
    font-size: 17px
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .job_logo {
    height: auto;
    width: 95px;
    margin: 10px auto
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .job_logo img {
    max-height: 100%;
    max-width: 100%;
    object-fit: cover
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .summary_info {
    padding: 12px
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .summary_info ul li {
    font-size: 15px;
    color: #5c5c5c;
    font-weight: 500;
    margin-bottom: 6px
}

.JobItemDetails .history_item .job_info .info_2 .job_summary .summary_info ul li span {
    font-weight: 400;
    color: #5c5c5c
}

.JobItemDetails .history_item .job_text {
    padding: 10px 0
}

.JobItemDetails .history_item .job_text h6 {
    font-size: 17px;
    color: #333;
    font-weight: 600;
    line-height: 18px;
    display: flex;
    align-items: center
}

.JobItemDetails .history_item .job_text h6 .MuiSvgIcon-root {
    font-size: 10px;
    margin-right: 8px
}

.JobItemDetails .history_item .job_text p {
    font-size: 15px;
    color: #5c5c5c;
    line-height: 24px;
    font-weight: 400;
    padding-left: 20px;
    text-align: start
}

.JobItemDetails .history_item .apply_btn {
    text-align: center;
    border-bottom: 1px solid #d5d5d5;
    padding: 30px 0;
    margin: 0 100px
}

.JobItemDetails .history_item .apply_btn a {
    padding: 11px 26px;
    background-color: #089808;
    color: #fff;
    font-weight: 500;
    border-radius: 4px;
    font-size: 15px
}

.JobItemDetails .history_item .other_option {
    text-align: center;
    padding-top: 15px
}

.JobItemDetails .history_item .other_option h5 {
    font-size: 18px;
    font-weight: 500;
    color: #333
}

.JobItemDetails .history_item .other_option h6 {
    font-size: 16px;
    color: #5c5c5c
}

.JobItemDetails .history_item .other_option h6 span {
    color: #089808;
    font-weight: 400
}

.top_section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 20px
}

img.ant-image-preview-img {
    display: inline !important;
    zoom: 65% !important;
    margin-left: 20% !important;
}

/* .css-n26ql7-MuiInputBase-input-MuiOutlinedInput-input {
    width: 330px !important;
} */

.css-1sq9zb { 
    padding: 88px !important; 
 }