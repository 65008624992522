@import"https://fonts.googleapis.com/css2?family=Frank+Ruhl+Libre:wght@400;500;600;700;800;900&family=Noto+Serif:wght@400;700&family=Open+Sans:wght@400;500;600;700;800&family=Roboto:wght@300;400;500;700;900&display=swap";

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box
}

body {
    font-family: "Roboto", sans-serif;
    scroll-behavior: smooth;
    background-color: #f1f1f1;
    font-weight: 400;
    font-size: 16px;
    color: #333;
    line-height: 24px
}

a {
    text-decoration: none
}

.w-img img {
    width: 100%
}

.m-img img {
    max-width: 100%
}

a,
.btn,
button,
span,
p,
i,
input,
select,
textarea,
li,
img,
svg path,
*::after,
*::before,
.transition-3,
h1,
h2,
h3,
h4,
h5,
h6 {
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

a:focus,
.button:focus {
    text-decoration: none;
    outline: none
}

a:focus,
a:hover {
    color: inherit;
    text-decoration: none
}

.uppercase {
    text-transform: uppercase
}

.capitalize {
    text-transform: capitalize
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: #2c3941;
    line-height: 24px;
    -webkit-transition: all .3s ease-out 0s;
    -moz-transition: all .3s ease-out 0s;
    -ms-transition: all .3s ease-out 0s;
    -o-transition: all .3s ease-out 0s;
    transition: all .3s ease-out 0s
}

h1 {
    font-size: 50px;
    font-weight: 700
}

h2 {
    font-size: 36px;
    font-weight: 500
}

h3 {
    font-size: 24px;
    font-weight: 500
}

h4 {
    font-size: 20px;
    font-weight: 400
}

h5 {
    font-size: 16px;
    font-weight: 400
}

h6 {
    font-size: 15px;
    font-weight: 400
}

ul {
    margin: 0px;
    padding: 0px
}

li {
    list-style: none
}

p {
    font-size: 15px;
    line-height: 24px;
    color: #7f8d9d
}

*::-moz-placeholder {
    color: #7f8d9d;
    font-size: 14px;
    opacity: 1
}

*::placeholder {
    color: #7f8d9d;
    font-size: 14px;
    opacity: 1
}

.fix {
    overflow: hidden
}

.clear {
    clear: both
}

.f-left {
    float: left
}

.f-right {
    float: right
}

.z-index-1 {
    z-index: 1
}
.w-10{
    width: 10%;
  }
  .w-15{
      width: 15%;
  }
  .w-20{
      width: 20%;
  }
  .w-25{
      width: 25%;
  }
  .w-30{
      width: 30%;
  }
  .w-35{
      width: 35%;
  }
  .w-40{
      width: 40%;
  }
  .w-45{
      width: 45%;
  }
  .w-50{
      width: 50%;
  }
  .w-60{
      width: 60%;
  }
  .w-70{
      width: 70%;
  }
  .w-80{
      width: 80%;
  }
  .w-90{
      width: 90%;
    }
    .mt-5{
        margin-top: 5px;
      }
  .mt-10{
    margin-top: 10px;
  }
  .mt-20{
    margin-top: 20px;
  }
  .mt-30{
    margin-top: 30px;
  }
  .mt-50{
    margin-top: 50px;
  }
  .mb-5{
    margin-bottom: 5px;
  }
.mb-10{
margin-bottom: 10px;
}
.mb-20{
margin-bottom: 20px;
}
.mb-30{
margin-bottom: 30px;
}
.mb-50{
margin-bottom: 50px;
}
.overflow-y-visible {
    overflow-x: hidden;
    overflow-y: visible
}

.overflow-x-visible {
    overflow-x: visible;
    overflow-y: hidden
}

.p-relative {
    position: relative
}

.profile_body {
    width: calc(100% - 50px);
    margin: 20px auto;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, .1294117647);
    border-radius: 16px;
    background-color: #fff;
    overflow: hidden;
    padding: 30px
}

.profile_body .profile_wrapper .profile_top {
    text-align: center
}

.profile_body .profile_wrapper .profile_top .profile_img {
    background-color: #fff;
    display: inline-block;
    border-radius: 50%;
    height: 142px;
    width: 142px;
    border: 1px solid #d5d5d5
}

.profile_body .profile_wrapper .profile_top .profile_img img {
    height: 140px;
    width: 140px;
    object-fit: contain;
    border-radius: 50%
}

.profile_body .profile_wrapper .profile_top .user_info .name {
    font-size: 26px;
    font-weight: 600;
    text-transform: uppercase;
    color: #333;
    padding: 6px 0 8px 0
}

.profile_body .profile_wrapper .profile_top .user_info .userDesignation {
    font-size: 14px;
    color: #4c5d76;
    font-weight: 400
}

.profile_body .profile_wrapper .profile_top .user_info .userDesignation span {
    border-left: 1px solid #d5d5d5;
    color: rgba(64, 61, 61, .7607843137);
    padding-left: 10px
}

.profile_body .profile_wrapper .profile_top .user_info .tme_rating {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 5px
}

.profile_body .profile_wrapper .profile_content {
    margin-top: 40px
}

.profile_body .profile_wrapper .profile_content .profile_content_left {
    border-right: 1px solid rgba(60, 59, 59, .3647058824);
    padding-right: 40px
}
.profile_body .profile_wrapper .profile_content .profile_content_left_bio {
    border-left: 1px solid rgba(60, 59, 59, .3647058824);
    padding-left: 40px
}

.profile_body .profile_wrapper .profile_content .profile_content_left .heading {
    display: flex;
    justify-content: end;
    position: relative;
    padding-bottom: 12px
}

.profile_body .profile_wrapper .profile_content .profile_content_left .heading h4 {
    font-size: 19px;
    font-weight: 500;
    color: #3f4755;
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 1px dashed #d5d5d5;
    padding-bottom: 5px
}

.profile_body .profile_wrapper .profile_content .profile_content_left .margin_top {
    margin-top: 40px
}

.profile_body .profile_wrapper .profile_content .profile_content_left .profile_bio {
    font-size: 14px;
    font-weight: 400;
    color: rgba(64, 61, 61, .7607843137);
    line-height: 23px;
    text-align: end
}

.profile_body .profile_wrapper .profile_content .profile_content_left .info_wrapper .info_list_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px
}

.profile_body .profile_wrapper .profile_content .profile_content_left .info_wrapper .info_list_item .list_item_left {
    display: flex;
    align-items: center;
    font-size: 14px;
    color: rgba(64, 61, 61, .7607843137)
}

.profile_body .profile_wrapper .profile_content .profile_content_left .info_wrapper .info_list_item .list_item_left .MuiSvgIcon-root {
    font-size: 20px;
    margin-right: 6px
}

.profile_body .profile_wrapper .profile_content .profile_content_left .info_wrapper .info_list_item .list_item_right {
    font-size: 15px;
    color: #000
}

.profile_body .profile_wrapper .profile_content .profile_content_left .education_wrapper .education_Item {
    padding-top: 12px;
    display: flex;
    justify-content: space-between;
    align-items: center
}

.profile_body .profile_wrapper .profile_content .profile_content_left .education_wrapper .education_Item .educationName {
    font-size: 20px;
    color: #333;
    font-weight: 500
}

.profile_body .profile_wrapper .profile_content .profile_content_left .education_wrapper .education_Item .educationName span {
    font-size: 15px;
    color: rgba(64, 61, 61, .7607843137);
    font-weight: 400
}

.profile_body .profile_wrapper .profile_content .profile_content_left .education_wrapper .education_Item .education_grade {
    font-size: 20px;
    font-weight: 600;
    color: #333
}

.profile_body .profile_wrapper .profile_content .profile_content_right {
    padding-left: 40px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .heading h4 {
    font-size: 19px;
    font-weight: 500;
    color: #3f4755;
    text-transform: uppercase;
    display: inline-block;
    border-bottom: 1px dashed #d5d5d5;
    padding-bottom: 5px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .experience_wrapper {
    padding-top: 5px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .experience_wrapper .experience_item {
    padding-top: 12px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .experience_wrapper .experience_item .workName {
    font-size: 18px;
    color: #333;
    font-weight: 500;
    line-height: 28px;
    display: flex;
    align-items: center
}

.profile_body .profile_wrapper .profile_content .profile_content_right .experience_wrapper .experience_item .workName .MuiSvgIcon-root {
    font-size: 22px;
    margin-right: 6px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .experience_wrapper .experience_item .workPlace {
    font-size: 15px;
    font-weight: 400;
    color: #333;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 2px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .experience_wrapper .experience_item .workPlace span {
    color: rgba(64, 61, 61, .7607843137);
    font-size: 14px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .skill_wrapper {
    padding-top: 5px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .skill_wrapper .skill_item {
    padding: 2px 10px;
    border: 1px solid #d5d5d5;
    font-size: 14px;
    color: #333;
    margin-right: 10px;
    border-radius: 4px;
    display: inline-block;
    margin-top: 10px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .hpobby_wrapper ul {
    margin-left: 10px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .hpobby_wrapper ul li {
    list-style: square;
    font-size: 15px;
    color: rgba(64, 61, 61, .7607843137);
    padding-top: 10px
}

.profile_body .profile_wrapper .profile_content .profile_content_right .margin_top {
    margin-top: 30px
}

.profile_body .note {
    padding-top: 40px
}

.profile_body .note p {
    font-size: 15px;
    color: rgba(64, 61, 61, .7607843137);
    line-height: 23px;
    font-weight: 400
}

.profile_body .heading2 {
    display: flex;
    position: relative;
    padding-top: 30px
}

.profile_body .heading2 h4 {
    font-size: 19px;
    font-weight: 500;
    color: #3f4755;
    display: inline-block
}

.tme_Card {
    padding-top: 12px
}

.tme_Card p {
    font-size: 14px;
    font-weight: 400;
    color: rgba(64, 61, 61, .7607843137);
    line-height: 22px
}

.tme_Card .review_btn {
    height: 50px;
    width: 100%;
    border: 1px solid #d5d5d5;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    border-radius: 6px;
    font-size: 16px;
    color: rgba(64, 61, 61, .7607843137);
    margin-top: 20px;
    cursor: pointer
}

.tme_Card .review_btn:hover {
    background-color: rgba(222, 221, 221, .1450980392);
    color: #333
}

.tme_Card .tme_Wrapper {
    width: 100%
}

.tme_Card .tme_Wrapper .tme_rating_container {
    display: flex;
    justify-content: center;
    margin-bottom: 12px
}

.tme_Card .tme_Wrapper .tme_rating_container .tme_wrapper {
    display: inline-block;
    padding: 3px 25px;
    border-radius: 35px;
    background-color: rgba(222, 221, 221, .2784313725)
}

.tme_Card .tme_Wrapper h5 {
    font-size: 14px;
    color: rgba(64, 61, 61, .7607843137);
    font-weight: 400;
    text-align: center
}

.tme_Card .tme_Wrapper .score_faq {
    display: flex;
    justify-content: center
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap {
    display: flex;
    align-items: center;
    margin-bottom: 5px
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_left {
    font-size: 14px;
    color: rgba(64, 61, 61, .7607843137);
    font-weight: 400
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_middle {
    margin: 0 10px;
    background-color: rgba(208, 207, 207, .3254901961);
    height: 16px;
    width: 200px;
    border-radius: 35px;
    display: flex;
    align-items: center;
    padding: 0 3px
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_middle .rating_bar {
    background-color: #faaf00;
    height: 12px;
    border-radius: 35px
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_middle .rating_five {
    width: 100%
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_middle .rating_four {
    width: 75%
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_middle .rating_three {
    width: 50%
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_middle .rating_two {
    width: 25%
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_middle .rating_one {
    width: 10%
}

.tme_Card .tme_Wrapper .score_faq .score_wrapper .score_Tab_wrap .tab_right {
    font-size: 15px;
    color: #333;
    font-weight: 400
}

.tme_Card .tme_Wrapper h6 {
    font-size: 15px;
    color: rgba(64, 61, 61, .7607843137);
    font-weight: 400;
    text-align: center;
    padding-top: 50px
}

.tme_Card .review {
    padding: 12px;
    border: 1px solid #d5d5d5;
    border-radius: 5px;
    margin: 20px 0 10px 0
}

.tme_Card .review .review_bottom {
    padding-top: 3px
}

.review_list_wrapper {
    padding-top: 20px
}

.review_list_wrapper .review_list {
    padding-top: 14px
}

.review_list_wrapper .review_list .review_list_top {
    display: flex
}

.review_list_wrapper .review_list .review_list_top .reviewer_avatar img {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 50%
}

.review_list_wrapper .review_list .review_list_top .review_score {
    padding-left: 6px
}

.review_list_wrapper .review_list .review_list_top .review_score ul {
    display: flex;
    align-items: center
}

.review_list_wrapper .review_list .review_list_top .review_score ul li .MuiSvgIcon-root {
    font-size: 20px;
    color: #faaf00
}

.review_list_wrapper .review_list .review_list_top .review_score .review_text {
    font-size: 13px;
    color: rgba(64, 61, 61, .7607843137);
    font-weight: 400;
    line-height: 20px
}

.webcardBody {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center
}

.webcardBody .webcard_wrap {
    height: 550px;
    width: 330px;
    position: relative
}

.webcardBody .webcard_wrap img {
    height: 100%;
    width: 100%;
    object-fit: contain
}

.webcardBody .webcard_wrap .webcard_dropdown {
    position: absolute;
    top: 20px;
    right: 20px;
    height: 35px;
    width: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(191, 188, 188, .3764705882);
    border-radius: 50%;
    cursor: pointer
}

.webcardBody .webcard_wrap .webcard_dropdown .MuiSvgIcon-root {
    color: #f3f3f3
}


/* Application details css */
.application_detail{
    padding: 20px 30px;
    background-color: #fff;
    height: auto;
    width: 100%;
}

.application_detail .application_date{
    font-size: 16px;
    font-weight: 500;
    color: #525151;
}
.application_detail .application_title {
    font-size: 28px;
    font-weight: 700;
    color: #333;
    padding-top: 30px;
}

.application_detail .application_body {
    padding-top: 30px;
}
.application_detail .application_body p{
    font-size: 16px;
    font-weight: 400;
    color: #5d5d5d;
    padding-bottom:20px;
}

.application_detail .application_table{
    height: auto;
    border: 1px solid #9c9c9cb9;
    margin-top: 20px;
    padding: 15px 12px;
    border-radius: 10px;
}
.application_table .table_heading .heading_item{
    font-size: 15px;
    font-weight: 700;
    color: #333;
    text-transform: uppercase;
}
.application_table .table_heading .text_center{
    text-align: center;
}
.application_table .table_heading .text_end{
  text-align: end;
}

.application_table .table_data_wrap .table_data{
    font-size: 15px;
    font-weight: 400;
    color: #262626;
    padding-top: 20px;
}
.application_table .table_data_wrap .text_center{
    text-align: center;
}
.application_table .table_data_wrap .text_end{
  text-align: end;
}
.application_table .table_data_wrap .table_data_status{
    width: 100%;
    display: flex;
    justify-content: end;
}
.application_table .table_data_wrap .payment_stauts{
  padding: 2px 15px;
  background-color: red;
  border-radius: 35px;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
}
.application_table .table_data_wrap .payment_stauts_paid{
  padding: 2px 15px;
  background-color: green;
  border-radius: 35px;
  color: #fff;
  display: inline-block;
  font-weight: 500;
  font-size: 13px;
}
.cmMedia{
    margin-left: 20px !important;
}
.input_box{
  position: relative;
}
.input_box_dropper i{
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
}
.input_box_dropper{
    min-height: 220px;
    max-height: 380px;
    width: 100%;
    background-color: #fff;
    border-bottom-right-radius: 16px;
    border-bottom-left-radius: 16px;
    box-shadow: 0px 1px 2px 2px rgba(0, 0, 0, .1294117647);
    position: absolute;
    top: 55px;
    left: 0;
    z-index: 10;
    padding: 10px;
    overflow-y: scroll;
}
.input_box_dropper ul li{
    padding: 5px 10px;
    cursor: pointer;
    color: #444444;
}
.input_box_dropper ul li:hover{
    background-color: #f3f3f3;
    border-radius: 5px;
}
.video-react {
    height: 100px !important;
    padding-top: 0px !important;
}

.css-1wpwbv3 {
    border-radius: 0% !important;
}
    
.css-soonpg {
    border-radius: 0% !important;
}

.cursor-pointer{
    cursor: pointer;
}
.d-flex{
    display: flex !important;
}
.align-center{
    align-items: center;
}
.justify-center{
    justify-content: center;
}
.d-center{
    display: flex !important;
    align-items: center;
}
.page-top{
    display: flex;
    justify-content: space-between;
}
.page-top h4{
  font-size: 20px;
  font-weight: 500;
  color: #333;
}
.banking-top{
    display: flex;
    justify-content: space-between;
    padding-bottom: 20px;
}
.banking-top h4{
  font-size: 16px;
  font-weight: 500;
  color: #333;
}
.icon-button{
    min-width: 25px !important;
    padding: 5px 0px  !important;
}
.page-content{
    padding: 10px 12px 0 12px;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 20px;
} 
.banking-page-content{
    padding: 15px 20px 15px 20px;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 20px;
}
.form-content{
    padding: 25px 20px 20px 20px;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 20px;
}
.table-container{
    height: 800px;
    max-height: 800px;
}
.lebel-text{
    font-size: 14px;
}
.content-wrap .MuiFormLabel-root{
  font-size: 14px !important;
  color: #333;
  font-weight: 600;
}
.content-wrap{
    margin-bottom: 5px;
}

.form-content h4{
    font-size: 16px;
    font-weight: 600;
    color: #333;
    margin-bottom: 15px;
}

.fixed-save-area{
    position: fixed;
    bottom: 0;
    height: 60px;
    width: calc(100% - 200px);
    background-color: #fff;
    z-index: 99;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px;
    padding: 15px 20px 0 20px;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.radio-card{
    padding: 5px 20px;
    border-radius: 8px;
    background-color: #f3f3f3;
    margin-right: 20px;
    margin-top: 12px;
}

.custom-table{
    width: 100%;
    /* border: 1px solid #c3c3c370; */
}

.custom-table table {
    border-collapse: collapse;
    width: 100%;
}
.custom-table thead {
    background-color: #f5f5f5;
}
.custom-table tbody {
    width: 100%;
}
.custom-table tbody {
 overflow-y: scroll;
 width: 100%;
}
.custom-table table tr{
    width: 100%;
}
.custom-table th {
    border: solid 1px #c3c3c370;
    font-size: 11px;
    padding: 7px 10px;
    text-align: start;
    text-transform: uppercase;
}
.custom-table td{
    border: solid 1px #c3c3c370;
    font-size: 12px;
    padding: 7px 10px;
    text-align: start;
}
.custom-table td span{
    font-weight: 500;
    font-size: 14px;
}
.color-red{
    color: red;
}
.color-green{
    color: rgb(16, 150, 16);
}
.color-yellow{
    color: rgb(224, 178, 14);
}

.text-center{
    text-align: center !important;
}
.text-end{
    text-align: end !important;
}
.table-input-field::before{
    border-bottom: none !important;
}
.table-input-field::after{
    border-bottom: none !important;
}
.account-input-field{
    border: 1px solid #c3c3c394;
    padding: 0 10px;
    border-radius: 5px;
    background-color: #fff;
}
.account-input-field::before{
    border-bottom: none !important;
}
.account-input-field::after{
    border-bottom: none !important;
}

.input-end input{
  text-align: end !important;
}
.balance-card-wrapper{
    padding-top: 20px;
    display: flex;
    flex-wrap: wrap;
}
.balance-card{
    display: flex;
    margin-right: 30px;
}
.balance-card .balance-card-icon{
  height: 40px;
  width: 40px;
  border: 1px solid #c3c3c330;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.balance-card .color-1{
    background-color: #98d3ad71;
}
.balance-card .color-1 .MuiSvgIcon-root{
  color: #00AB55;
}
.balance-card .color-2{
    background-color: #cac38247;
}
.balance-card .color-2 .MuiSvgIcon-root{
  color: rgb(223, 173, 21);
}
.balance-card .color-3{
    background-color: #cececc47;
}
.balance-card .color-3 .MuiSvgIcon-root{
  color: rgb(98, 95, 95);
}
.balance-card .color-4{
    background-color: #b5cbe747;
}
.balance-card .color-4 .MuiSvgIcon-root{
  color: rgb(63, 112, 190);
}
.balance-card-content{
    padding-left: 10px;
}
.balance-card-content h6{
  font-size: 12px;
  line-height: 18px;
}
.balance-card-content h5{
    font-size: 15px;
    font-weight: 600;
    color: #333;
    line-height: 20px;
  }

.note-text h4{
  font-size: 16px;
  color: #333;
  font-weight: 600;
}
.note-text ul{
    padding-left: 20px;
}
.note-text ul li{
    list-style: square;
    font-size: 13px;
    color: #404040;
    margin-top: 5px;
}

.account-card{
    width: 100%;
    background-color: #f9f9fb;
    border-radius: 12px;
    padding: 15px 20px;
}

.account-card .account-card-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.account-card .account-card-top h5{
    font-size: 14px;
    font-weight: 600;
    color: #333;
}

.account-card .account-card-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.account-card .account-card-bottom h5{
    font-size: 16px;
    font-weight: 600;
    color: #333;
}

.invoice-payment-summury{
    width: 100%;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 20px;
    padding: 15px 18px;
}

.invoice-payment-summury-wrap{
    display: flex;
    margin-top: 20px;
}

.invoice-payment-summury-wrap .payment-summury-icon i{
   height: 40px;
   width: 40px;
   background-color: #1cad64;
   border-radius: 50%;
   display: flex;
   justify-content: center;
   align-items: center;
}

.invoice-payment-summury-wrap .payment-summury-content{
  padding-left: 10px;
}

.invoice-payment-summury-wrap .payment-summury-content h6{
    line-height: 20px;
}
.invoice-payment-summury-wrap .payment-summury-content h5{
    margin-top: 6px;
}
.account-home-top{
    width: 100%;
    background-color: #fcfcfe;
    position: relative;
    z-index: 1;
    border-radius: 12px;
    overflow: hidden;
    padding: 15px 15px 0 15px;
}
.account-home-top::after{
    position: absolute;
    content: '';
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    background-image: url(../../assets/image/account-home-bg.svg);
    background-position: center;
    background-repeat: repeat;
    z-index: -1;
}

.home-top-info-wrap{
    display: flex;
    margin-bottom: 40px;
}
.home-top-info-wrap i{
    height: 42px;
    width: 42px;
    border: 1px solid #929292;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-top-info-wrap .home-top-info{
    padding-left: 10px;
}
.account-card-item{
    width: 100%;
    background-color: #fff;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
    border-radius: 12px;
    padding: 25px;
}
.account-card-item .account-card-top{
  display: flex;
  justify-content: space-between;
  border-bottom: 1px solid #c3c3c37c;
  padding-bottom: 10px;
}
.account-card-item  .account-card-bottom{
  border-top: 1px solid #c3c3c37c;
  padding-top: 10px;
}
.account-card-lineProgress{
    height: 12px !important;
    margin: 20px 0 22px 0;
}

.flow-content{
    width: 100%;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #c3c3c384;
    margin-top: 14px;
    overflow: hidden;
}
.flow-content .flow-content-top{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid #c3c3c37c;
    padding: 12px;
}
.flow-content .flow-content-bottom{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top: 1px solid #c3c3c37c;
    padding: 12px;
    background-color: #ffffff;
}
.flow-content .flow-content-bottom h6{
    font-size: 13px;
    display: inline-block;
    cursor: pointer;
}
.flow-menu{
    border: 1px solid #c3c3c393;
    border-radius: 35px;
    padding: 5px 22px;
    font-size: 12px;
    background-color: #eef3f8;
    display: flex;
    align-items: center;
    line-height: 0px;
}
.flow-info-wrapper{
    padding: 20px;
}

.flow-info-wrapper p{
    font-size: 12px;
}

.flow-pie-chart{
    padding: 70px 10px 70px 10px;
}

.bulk-center-contetn{
    max-width: 500px;
    margin: 160px auto;
}

.bulk-center-contetn h3{
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    padding: 10px 0;
}

.bulk-center-contetn h5{
    font-size: 14px;
}

.page-top-buttons h6{
    font-size: 14px;
    color: #1cad64;
}
.common-container{
    padding: 20px 20px 20px 20px;
    background-color: #fff;
    border-radius: 16px;
    margin-top: 20px;  
}

.transaction-card{
    border: 1px solid #c3c3c35a;
    border-radius: 12px;
    display: flex;
    padding: 20px;
}
.transaction-card:hover{
    background-color: #f4f8fc;
}

.transaction-card .transaction-card-left{
  display: flex;
  width: 70%;
}
.transaction-card .transaction-card-right{
  display: flex;
}
.transaction-card .transaction-card-left i{
    height: 45px;
    width: 45px;
    border: 1px solid #c3c3c35d;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;
}

.transaction-card .transaction-card-left .lock-content{
  padding-left: 10px;
}
.transaction-card .transaction-card-right{
  display: flex;
  justify-content: flex-end;
  width: 30%;
}

.transaction-card .transaction-card-right .card-status{
  font-size: 13px;
  color: #1cad64;
  display: flex;
  align-items: center;
}