.extra_img_relative {
    position: relative;
}
.extra_img_overly2 {
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
    color: #fff;
    font-size: 45px;
    border-radius: 10px;
}
.upload_box_container {
    width: 100%;
    padding: 18px 18px 18px 18px;
    border-radius: 13px;
    background-color: #fff;
    box-shadow: 0px 1px 1px 1px rgba(0, 0, 0, 0.1294117647);
    margin-bottom: 20px;
    cursor: pointer;
}
.upload_box_top{display:flex}
.uploader_profile {
    width: 45px;
    height: auto;
}
 .uploader_input {
    width: 100%;
    margin-left: 8px;
    color: #787878;
}
  .form_control {
    display: block;
    width: 100%;
    height: 45px;
    padding: 10px;
    color: #787878;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: 35px;
    border: 1px solid #d5d5d5;
    transition: border-color .15s }


    .dialog_header {
        border-bottom: 1px solid rgba(202,221,235,.937);
        color: #333;
        font-size: 20px;
        font-weight: 500;
        padding: 15px 0;
        position: relative;
        text-align: center;
        width: 100%;
    }

    .closeBtn {
        cursor: pointer;
        position: absolute;
        top: 50%;
        -webkit-transform: translateY(-50%);
        transform: translateY(-50%);
        right: 10px;
        z-index: 3;
    
    }
    .closeBtn:hover{
        color: red;
    }

    .d-none{
        display: none !important;
    }
