.circleLoader {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.css-q1d48x-MuiButtonBase-root-MuiIconButton-root {
  background-color: rgba(0, 171, 86, 0.164) !important;
}

.css-v9eyel-MuiInputBase-input {
  background-color: #9393931a !important;
  border-radius: 10px !important;
  padding: 10px 5px !important;
}
.css-u0lq3i {
  display: flex !important;
  justify-content: flex-end !important;
}
.css-iyf0b1-MuiPaper-root-MuiCard-root {
  background-color: #e5e5e573 !important;
}
.css-g8xom6-MuiInputBase-input-MuiInput-input {
  width: 650px !important;
}
.css-s4jcgb-MuiSvgIcon-root {
  cursor: pointer !important ;
}
/* user in formation */
.user_information {
  height: auto;
  width: 1124px;
  background-color: #fff;
  margin: 10px auto;
  padding: 20px 30px;
  position: relative;
}

.user_information .company_logo {
  height: 80px;
  width: 190px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
}

.user_information .company_logo img {
  max-width: 100%;
  max-height: 100%;
}

.user_information .info_top {
  display: flex;
  padding-top: 20px;
  border-bottom: 1px solid rgba(213, 210, 210, 0.667);
  padding-bottom: 30px;
}

.user_information .info_top .user_profile_pic {
  height: 100px;
  width: 100px;
  border-radius: 50%;
  overflow: hidden;
  background-color: #f3f3f3;
}

.user_information .info_top .user_profile_pic img {
  max-width: 100%;
  max-width: 100%;
}

.user_information .info_top .user_profile_info {
  padding-top: 12px;
}

.user_information .info_top .user_profile_info .userName {
  font-size: 28px;
  color: #333;
  font-weight: 600;
  line-height: 38px;
  padding-left: 10px;
}

.user_information .info_top .user_profile_info ul {
  display: flex;
}

.user_information .info_top .user_profile_info ul li {
  display: flex;
  align-items: center;
  padding-left: 10px;
  font-size: 14px;
  color: #616161;
}

.user_information .info_top .user_profile_info ul li i {
  display: flex;
  align-items: center;
  margin-right: 4px;
}

.user_information .info_top .user_profile_info ul li i .MuiSvgIcon-root {
  font-size: 18px;
  color: rgba(91, 89, 89, 0.8235294118);
}

.user_information .address_wrapper ul li {
  font-size: 16px;
  font-weight: 400;
  color: #333;
  display: inline-block;
  padding-right: 45px;
}

.user_information .address_wrapper ul li span {
  font-weight: 500;
  font-size: 17px;
}

.user_information .address_wrapper .address_detail {
  padding-top: 12px;
}

.user_information .address_wrapper .address_detail h5 {
  font-size: 14px;
  color: #565555;
  font-weight: 400;
  line-height: 24px;
}

.user_information .address_wrapper .address_detail p {
  color: #333;
  font-weight: 400;
  font-size: 16px;
}

.user_information .heading_section {
  font-size: 22px;
  color: #333;
  font-weight: 600;
  padding: 10px 0;
}

.user_information .extra_margin {
  margin-top: 20px;
}

.user_information .bio_wrapper p {
  font-size: 14px;
  color: #565555;
  font-weight: 400;
  line-height: 22px;
}

.user_information .bio_wrapper .spesiality_list {
  padding-top: 10px;
}

.user_information .bio_wrapper .spesiality_list ul li {
  padding: 5px 15px;
  background-color: #f4f6fa;
  display: inline-block;
  font-size: 12px;
  color: #616161;
  margin-right: 12px;
  border-radius: 35px;
}

.user_information .user_personal_info {
  height: auto;
  width: 300px;
  background-color: #f4f6fa;
  margin: 40px 0 0 auto;
  border-radius: 16px;
  padding: 20px;
}

.user_information .user_personal_info h4 {
  font-size: 18px;
  color: #333;
  font-weight: 500;
  padding-bottom: 10px;
}

.user_information .user_personal_info .gen_info {
  display: flex;
  padding: 5px 0;
}

.user_information .user_personal_info .gen_info i {
  border: 1px solid rgba(145, 141, 141, 0.656);
  height: 40px;
  width: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.user_information .user_personal_info .gen_info i .MuiSvgIcon-root {
  font-size: 18px;
  color: #434242;
}

.user_information .user_personal_info .gen_info .gen_info_wrapp {
  padding-top: 5px;
  padding-left: 5px;
}

.user_information .user_personal_info .gen_info .gen_info_wrapp .gen_name {
  font-size: 15px;
  color: #333;
  font-weight: 400;
  line-height: 18px;
}

.user_information .user_personal_info .gen_info .gen_info_wrapp .gen_title {
  font-size: 13px;
  color: #565555;
}

.user_information .personal_item {
  padding-top: 14px;
}

.user_information .personal_item h5 {
  font-size: 16px;
  color: #333;
  font-weight: 400;
  display: inline-block;
  border-bottom: 1px solid rgba(139, 137, 137, 0.2274509804);
}

.user_information .personal_item p {
  font-size: 15px;
  color: #565555;
  font-weight: 400;
  line-height: 24px;
  padding-top: 5px;
  padding-top: 7px;
  text-align: start;
}

.user_information .educational_item {
  display: flex;
  padding: 5px 0;
  margin: 10px 0;
}

.user_information .educational_item i {
  border: 1px solid rgba(145, 141, 141, 0.656);
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
}

.user_information .educational_item i .MuiSvgIcon-root {
  font-size: 30px;
  color: #434242;
}

.user_information .educational_item .educational_item_wrapp {
  padding-left: 5px;
  padding-top: 2px;
  width: 90%;
}

.user_information .educational_item .educational_item_wrapp h4 {
  font-size: 17px;
  color: #333;
  font-weight: 500;
  line-height: 22px;
}

.user_information .educational_item .educational_item_wrapp p {
  font-size: 14px;
  color: #565555;
}

.user_information .identity_wrapper {
  display: flex;
}

.user_information .identity_item {
  height: 70px;
  width: 210px;
  border: 1px solid rgba(167, 166, 166, 0.1725490196);
  background-color: #f4f6fa;
  border-radius: 10px;
  margin: 10px 15px 10px 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_information .identity_item .identity_wrap h4 {
  font-size: 17px;
  color: #333;
  font-weight: 500;
  line-height: 22px;
}

.user_information .identity_item .identity_wrap p {
  font-size: 14px;
  color: #565555;
  text-align: center;
}

.user_information .user_signature {
  height: 100px;
  width: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}

.user_information .user_signature img {
  max-width: 100%;
  max-height: 100%;
}

.user_information .signature_text {
  text-align: center;
  font-size: 15px;
  color: #333;
  font-weight: 400;
  padding-top: 5px;
}

* {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
}
.container {
  margin-top: 50px;
  display: flex;
  flex-flow: column;
  align-items: center;
}
.container .add-icon {
  width: 50px;
  height: 50px;
}
.container input {
  display: none;
}
.container .img-container {
  margin-top: 30px;
  border-radius: 50%;
  border: 3px solid black;
}
.container .img-container img {
  display: block;
  border-radius: 50%;
}
footer {
  margin-top: 100px;
}
footer a {
  color: black;
  text-decoration: none;
}
.card_dropdown_wrapper{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_dropdown_wrapper .card_dropdown_wrap{
  padding: 12px 12px;
  background-color: #fff;
  border-radius: 12px;
  width: 85%;
}
.card_dropdown_wrapper .card_dropdown_wrap li {
  font-size: 16px;
  color: #333;
  font-weight: 400;
  margin: 2px 0;
  padding: 8px;
  cursor: pointer;
  background-color: #f3f3f3;
  margin-bottom: 3px;
}
.card_dropdown_wrapper .card_dropdown_wrap li:hover{
  background-color: #e9e8e8;
  border-radius: 4px;
}

/* horizontal */
.card_dropdown_wrapper_h{
  position: absolute;
  top: 0;
  left: 0;
  z-index: 9;
  height: 100%;
  width: 100%;
  background-color: rgba(0,0,0,0.4);
  display: flex;
  align-items: center;
  justify-content: center;
}

.card_dropdown_wrapper_h .card_dropdown_wrap_h{
  padding: 12px 12px;
  background-color: #fff;
  border-radius: 12px;
  width: 40%;
}
.card_dropdown_wrapper_h .card_dropdown_wrap_h li {
  font-size: 16px;
  color: #333;
  font-weight: 400;
  margin: 2px 0;
  padding: 8px;
  cursor: pointer;
  background-color: #f3f3f3;
  margin-bottom: 3px;
}
.card_dropdown_wrapper_h .card_dropdown_wrap_h li:hover{
  background-color: #e9e8e8;
  border-radius: 4px;
}

.css-or2cpc-MuiPaper-root-MuiPopover-paper{
  width: 250px !important;
}

.cursor-pointer{
  cursor: pointer;
}


.attendence{
  position: absolute;
  top: 50%;
  left: 23%;
  transform: translate(-50%, -50%);
  user-select: none;
}
.pulse{
  width: 40px;
  height: 40px;
  background: #FF6D4A;
  border-radius: 50%;
  color: #FFF;
  font-size: 7px;
  text-align: center;
  line-height: 40px;
  font-family: sans-serif;
  text-transform: uppercase;
  /* animation: animate-pulse 3s linear infinite; */
  cursor: pointer;
  user-select: none;
}
.pulse:hover{
  width: 40px;
  height: 40px;
  background: #4a67f9;
  border-radius: 50%;
  color: #f5f2f2;
  font-size: 7px;
  text-align: center;
  line-height: 35px;
  font-family: sans-serif;
  text-transform: uppercase;
  /* animation: animate-pulse 1s linear infinite; */
  cursor: pointer;
  user-select: none;
}
@keyframes animate-pulse{
  0%{
      box-shadow: 0 0 0 0 rgba(255,109,74,0.7),  0 0 0 0 rgba(255,109,74,0.7);
  }
  40%{
      box-shadow: 0 0 0 50px rgba(255,109,74,0.0),  0 0 0 0 rgba(255,109,74,0.7);
  }
  80%{
      box-shadow: 0 0 0 50px rgba(255,109,74,0.0),  0 0 0 30px rgba(255,109,74,0);
  }
  100%{
      box-shadow: 0 0 0 0 rgba(255,109,74,0.0),  0 0 0 30px rgba(255,109,74,0);
  }
  
}
@keyframes animate-pulse-pressed{
  0%{
      box-shadow: 0 0 0 0 rgba(74, 98, 255, 0.7),  0 0 0 0 rgba(86, 74, 255, 0.7);
  }
  40%{
      box-shadow: 0 0 0 50px rgba(95, 74, 255, 0),  0 0 0 0 rgba(74, 77, 255, 0.7);
  }
  80%{
      box-shadow: 0 0 0 50px rgba(95, 74, 255, 0),  0 0 0 30px rgba(74, 98, 255, 0);
  }
  100%{
      box-shadow: 0 0 0 0 rgba(107, 74, 255, 0),  0 0 0 30px rgba(80, 74, 255, 0);
  }
  
}

.css-1m9ltwd .fc .fc-event .fc-event-main {
  background-color: #ccc !important;
}

.notice-card {
  padding: 5px 16px 0px 16px !important;
} 
.input_box_list2{
  margin-top: 10px !important;
}
.input_box_list2 ul{
  display: flex;
  flex-wrap: wrap;
}
.input_box_list2 ul li{
  display: flex;
  align-items: center;
  padding: 1px 8px 1px 15px;
  border-radius: 35px;
  font-size: 13px;
  font-weight: 400;
  color: #3f3e3e;
  background-color: #dfdfdfe6;
  margin: 3px 0 3px 8px;
}
.input_box_list2 ul li .MuiSvgIcon-root{
  padding-left: 5px;
  cursor: pointer;
}

.css-1wpwbv3 {
  border-radius: 0% !important;
}

.css-soonpg {
  border-radius: 0% !important;
}

.css-19g3kqp-MuiAvatar-root {
  width: 32px !important;
  height: 32px !important;
}

.cursorPointer{
  cursor: pointer !important;
}


.css-zp28te{
  display: none !important;
}
.css-zqva3r{
  display: none !important;
}
.css-tnrs2v{
  display: none !important;
}
.css-192mm82{
  display: none !important;
}
.css-jdxsyg{
  display: none !important;
}
.css-ied2z{
  display: none !important;
}
.css-1jzs0ww{
  display: none !important;
}
.css-je6mf0{
  display: none !important;
}
.css-1056odh{
  display: none !important;
}
.css-sbbpvu{
  display: none !important;
}
.css-17atddb{
  display: none !important;
}
.css-1mzipkw{
  display: none !important;
}


.css-12zaqky-MuiTableRow-root{
  display: none !important;
}
.css-29rat9-MuiTableRow-root{
    display: none !important;
}
.css-kueynj-MuiTableRow-root{
  display: none !important;
}
.css-11be5mm-MuiTableRow-root{
  display: none !important;
}
.css-1mp4ljq-MuiTableRow-root{
  display: none !important;
}
.css-1abi0nu-MuiTableRow-root{
  display: none !important;
}
.css-1nvk742-MuiTableRow-root{
  display: none !important;
}
.css-ibsqsl-MuiTableRow-root{
  display: none !important;
}
.css-1acip4c-MuiTableRow-root{
  display: none !important;
}
.css-z0f52-MuiTableRow-root{
  display: none !important;
}
.css-b4v4sj-MuiTableRow-root{
  display: none !important;
}
.css-1qu5b5z-MuiTableRow-root{
  display: none !important;
}

.task-lists{
  cursor: pointer !important;
}
.task-lists:hover {
  background: aliceblue !important;
}

.editIcon,.deleteIcon {
  color: cadetblue;
  font-size: 14px;
  cursor: pointer;
}

.task-container{
  max-height: 400px;
  overflow-y: scroll;
  overflow-x: hidden;
}

.css-k0inxa{
  min-width: 800px !important
}

.css-1cxwv5m-MuiPaper-root,.css-1sq9zb {
  background-color: #fff !important;
  color: #212B36 !important;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms !important;
  border-radius: 8px !important;
  box-shadow: none !important;
  background-image: none !important;
  padding: 8px !important;
  transition: 500px !important;
  background: #5BE584 !important;
}