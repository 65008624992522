@media (min-width: 280px) and (max-width: 991.98px) { 
	.card_img{
		padding: 15px;
	}
	.card_title{
		font-size: 24px !important;
		line-height:30px !important;
	}
	.profile_body {
		width: auto;
		margin: 20px auto;
		padding: 25px 14px;
	}
	.profile_body .profile_wrapper .profile_top .profile_img {
		height: 100px;
		width: 100px;
	}
	.profile_body .profile_wrapper .profile_top .profile_img img{
       height: 98px;
	   width: 98px;
	}
	.profile_body .profile_wrapper .profile_top .user_info .name {
		font-size: 22px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .profile_bio {
		text-align: start;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .heading {
		justify-content: start;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .heading h4 {
		font-size: 16px;
		padding-bottom: 2px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .info_wrapper .info_list_item {
		display: list-item;
		list-style: none;
		padding-top: 7px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .info_wrapper .info_list_item .list_item_right {
		text-align: end;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left {
		border-right:none;
		padding-right: 0px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left_bio {
		border-right:none;
		padding-right: 0px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .education_wrapper .education_Item {
		flex-wrap: wrap;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_right .heading h4 {
		font-size: 16px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .education_wrapper .education_Item .educationName {
		font-size: 16px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .education_wrapper .education_Item .educationName span {
		font-size: 14px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left .education_wrapper .education_Item .education_grade {
		font-size: 16px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_right {
		padding-left: 0px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_right {
		padding-left: 0px;
		padding-top: 20px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_right .experience_wrapper .experience_item .workPlace span {
		font-size: 13px;
	}
	.profile_body .note {
		padding-top: 12px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_right .experience_wrapper .experience_item .workPlace {
		font-size: 14px;
	}
}
@media (min-width: 901px) and (max-width: 991.98px) { 
	.profile_body .profile_wrapper .profile_content .profile_content_left {
		border-right: 1px solid #d5d5d5;
		padding-right: 15px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_left_bio {
		border-left: 1px solid #d5d5d5;
		padding-left: 15px;
	}
	.profile_body .profile_wrapper .profile_content .profile_content_right {
		padding-left: 15px;
		padding-top: 0px;
	}
	.profile_body .note {
		padding-top: 20px;
	}
}
 